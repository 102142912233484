import { translation } from '../../translations/translationProvider';

const camelCase = (text = '') =>
	text
		.replace(/\s(.)/g, (a) => a.toUpperCase())
		.replace(/\s/g, '')
		.replace(/^(.)/, (b) => b.toLowerCase());

function Literal({ lang, text }) {
	if (!lang) return '';
	if (!text) return '';
	return `${translation(lang.toLowerCase(), camelCase(text)) || text}`;
}

export { Literal as default, camelCase };

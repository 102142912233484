import { useEffect, useState } from 'react';
import { AvailabilityContext } from './AvailabilityContext';
import { ConfigContext } from './ConfigContext';
import { createContext, useContextSelector } from 'use-context-selector';

const AvailabilityPriceContextInstance = createContext({
	getAvailability: () => {},
	getEstablishmentOrder: (id) => {},
	isLoading: false
});

export const AvailabilityPriceContext = ({ children }) => {
	const config = useContextSelector(ConfigContext, (s) => s.config);
	const { ACCESS_TOKEN, GET_AVAILABILITY_PATH } = config;
	const API_ESTABLISHMENTS_URL = `${process.env.REACT_APP_API_URL}${GET_AVAILABILITY_PATH}?token=${ACCESS_TOKEN}`;

	const isSearching = useContextSelector(AvailabilityContext, (s) => s.isSearching);
	const getSearch = useContextSelector(AvailabilityContext, (s) => s.getSearch);

	const [availability, setAvailability] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const getAvailability = (id) => {
		if (!id) return availability;
		return availability.find(({ request }) => request.establishment === id);
	};

	const getEstablishmentOrder = (id) =>
		(availability.findIndex(({ request }) => request.establishment === id) + 1) * -1;

	useEffect(() => {
		const search = getSearch();
		if (!search || !isSearching) return;

		const sse = new EventSource(`${API_ESTABLISHMENTS_URL}&search=${JSON.stringify(search)}`);
		setAvailability([]);

		const onOpen = () => {
			setLoading(true);
		};
		sse.addEventListener('open', onOpen);

		const receiveMessage = (e) => {
			if (!e.data) return;
			let data = JSON.parse(e.data);
			if (!!data.error && data.error === true)
				data = { availability: {}, request: { establishment: data.establishment } };
			setAvailability((prev) => {
				const newAvailability = [...prev, data];
				newAvailability
					.sort((a, b) => {
						const aHas = !!a?.availability && Object.keys(a.availability).length > 0;
						const bHas = !!b?.availability && Object.keys(b.availability).length > 0;
						if (aHas && !bHas) return -1;
						if (!aHas && bHas) return 1;
						if (aHas && bHas)
							return Number(a.availability.minPrice) < Number(b.availability.minPrice) ? -1 : 1;
						return 0;
					})
					.reverse();
				return newAvailability;
			});
		};
		sse.addEventListener('message', receiveMessage);

		const receiveError = () => {
			sse.close();
			setLoading(false);
		};
		sse.addEventListener('error', receiveError);

		return () => {
			sse.removeEventListener('message', receiveMessage);
			sse.removeEventListener('error', receiveError);
			sse.removeEventListener('open', onOpen);
			sse.close();
			setLoading(false);
		};
	}, [isSearching, getSearch, API_ESTABLISHMENTS_URL]);

	return (
		<AvailabilityPriceContextInstance.Provider
			value={{ getAvailability, getEstablishmentOrder, isLoading }}>
			{children}
		</AvailabilityPriceContextInstance.Provider>
	);
};

export const useAvailabilityPriceContext = () => {
	const context = useContextSelector(AvailabilityPriceContextInstance);
	if (!context) throw new Error('Context must be used within a Provider');
	return context;
};

export const useAvailability = () =>
	useContextSelector(AvailabilityPriceContextInstance, (s) => s.getAvailability);
export const useEstablishmentOrder = () =>
	useContextSelector(AvailabilityPriceContextInstance, (s) => s.getEstablishmentOrder);
export const useIsLoading = () =>
	useContextSelector(AvailabilityPriceContextInstance, (s) => s.isLoading);

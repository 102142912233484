import { useState, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { translation } from '../../translations/translationProvider';
import NavBar from '../nav/NavBar';
import BookingSearch from './BookingSearch/BookingSearch';
import FilterForm from '../utilities/FilterForm';
import EstablishmentList from './EstablishmentList';
import Footer from './Footer';
import Cookies from './Cookies';

import {
	SpeedDial,
	SpeedDialIcon,
	SpeedDialAction,
	Snackbar,
	Slide,
	Box,
	SwipeableDrawer,
	styled
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ShareIcon from '@mui/icons-material/Share';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { AvailabilityPriceContext } from '../../contexts/AvailabilityPriceContext';
import { useContextSelector } from 'use-context-selector';

const filterFormWidth = 320;
const ContentWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open, addMargin = false, direction = 'right' }) => {
	const dir = direction.charAt(0).toUpperCase() + direction.slice(1);
	const properties = [];
	const style = {};
	if (addMargin) {
		style[`margin${dir}`] = `${filterFormWidth}px`;
		properties.push('margin');
	}

	return {
		transition: theme.transitions.create(properties, {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		...(open && {
			...style,
			transition: theme.transitions.create(properties, {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			})
		})
	};
});

function Main({ theme, user }) {
	const ctaColor = theme.cta.primary.color;
	const ctaTextLightColor = theme.colors.primary.textColor;
	const colorHover = theme.others.ctaHoverColor;
	const errorColor = theme.colors.error.color;
	const errorTextColor = theme.colors.error.textColor;
	const activeColor = theme.colors.active.color;
	const activeTextColor = theme.colors.active.textColor;

	const lang = useContextSelector(UserContext, (s) => s.lang);

	const [isFiltersOpened, openFilters] = useState(false);
	const [showTopButton, setShowTopButton] = useState(false);

	const [isSnackOpened, openSnack] = useState(false);
	const [snackMessage, setSnackMessage] = useState('');
	const [verticalSnackAlignment, setVerticalSnackAlignment] = useState('bottom');
	const [horizontalSnackAlignment, setHorizontalSnackAlignment] = useState('left');
	const [snackTransition, setSnackTransition] = useState(Slide);
	const [snackVariant, setSnackVariant] = useState('default');

	const snackVariantColors = {
		default: [ctaTextLightColor, ctaColor],
		success: [activeTextColor, activeColor],
		error: [errorTextColor, errorColor]
	};

	const speedDialActions = [
		{
			icon: <ShareIcon />,
			name: translation(lang.toLowerCase(), 'share'),
			onClick: async () => {
				let error = false;
				try {
					await navigator.clipboard.writeText(window.location.href);
				} catch (err) {
					error = true;
				}
				setSnackVariant(error ? 'error' : 'success');
				const message = translation(lang.toLowerCase(), error ? 'noPermissions' : 'linkCopied');
				setSnackMessage(`${message}${error ? `: ${window.location.href}` : ''}`);
				openSnack(true);
				setVerticalSnackAlignment('bottom');
				setHorizontalSnackAlignment('center');
				setSnackTransition(Slide);
			}
		},
		{
			icon: <FilterAltIcon />,
			name: translation(lang.toLowerCase(), 'filters'),
			onClick: () => openFilters(!isFiltersOpened)
		}
	];

	useEffect(() => {
		const scrollListener = () => setShowTopButton(document.documentElement.scrollTop > 1250);
		window.addEventListener('scroll', scrollListener, false);
		return () => window.removeEventListener('scroll', scrollListener);
	}, []);

	return (
		<>
			<ContentWrapper open={isFiltersOpened} addMargin={window.innerWidth - filterFormWidth > 320}>
				<NavBar
					clientLanguages={user.languages}
					logo={{ src: `${user.logo}`, width: `${user.width}`, height: `${user.height}` }}
					title={user.title.es}
					web={user.web.es}
					dataTheme={theme}
				/>
				<Box component={'header'}>
					<BookingSearch dataTheme={theme} />
				</Box>
				<Box component={'main'} className='container' position='relative'>
					<AvailabilityPriceContext>
						<EstablishmentList
							dataTheme={theme}
							isFiltersOpened={isFiltersOpened}
							openFilters={openFilters}
						/>
					</AvailabilityPriceContext>
					<Cookies user={user} theme={theme} />
				</Box>
				<Footer theme={theme} />
			</ContentWrapper>
			<ContentWrapper
				component={'aside'}
				open={isFiltersOpened}
				addMargin
				sx={{ position: 'fixed', bottom: 15, right: 30 }}>
				<SpeedDial
					ariaLabel='MetaSearch page actions'
					sx={{
						'& > button': { color: ctaTextLightColor, backgroundColor: ctaColor },
						'& > button:hover, & > button:focus': { backgroundColor: `${colorHover}` }
					}}
					icon={<SpeedDialIcon />}>
					{speedDialActions.map(({ name, icon, onClick }) => (
						<SpeedDialAction
							key={name}
							tooltipTitle={name}
							icon={icon}
							onClick={onClick}
							tooltipOpen
						/>
					))}
				</SpeedDial>
				<Slide direction='up' in={showTopButton} mountOnEnter unmountOnExit>
					<SpeedDial
						ariaLabel='MetaSearch page actions'
						sx={{
							'& > button': { color: ctaTextLightColor, backgroundColor: ctaColor },
							'& > button:hover, & > button:focus': { backgroundColor: `${colorHover}` }
						}}
						onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
						icon={<KeyboardArrowUpIcon />}></SpeedDial>
				</Slide>
			</ContentWrapper>
			<Snackbar
				anchorOrigin={{ vertical: verticalSnackAlignment, horizontal: horizontalSnackAlignment }}
				open={isSnackOpened}
				message={snackMessage}
				onClose={() => openSnack(false)}
				onClick={snackVariant === 'error' ? () => {} : () => openSnack(false)}
				autoHideDuration={snackVariant === 'error' ? 6000 : 2000}
				key={'snack'}
				TransitionComponent={snackTransition}
				action={
					<ClearIcon
						onClick={() => openSnack(false)}
						sx={{
							color: `${(snackVariantColors[snackVariant] || snackVariantColors['default'])[0]}`
						}}
					/>
				}
				sx={{
					'& > div': {
						color: `${(snackVariantColors[snackVariant] || snackVariantColors['default'])[0]}`,
						backgroundColor: `${
							(snackVariantColors[snackVariant] || snackVariantColors['default'])[1]
						}`
					},
					cursor: 'pointer'
				}}
			/>
			<SwipeableDrawer
				variant={window.innerWidth - filterFormWidth > 320 ? 'persistent' : 'temporary'}
				anchor='right'
				open={isFiltersOpened}
				onClose={() => openFilters(false)}
				onOpen={() => openFilters(true)}>
				<FilterForm theme={theme} width={filterFormWidth} handleClose={() => openFilters(false)} />
			</SwipeableDrawer>
		</>
	);
}

export default Main;

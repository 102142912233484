import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import {
	Backdrop,
	Button,
	CardMedia,
	CircularProgress,
	Dialog,
	DialogContent,
	MobileStepper,
	styled,
	Zoom
} from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function EstablishmentCardGalleryDialog({ theme, gallery, open, handleClose, index, defaultAlt }) {
	const ctaTextLightColor = theme.colors.secondary.textColor;
	const bgColor = '#fff';

	const ImgCard = styled(CardMedia)(({ theme }) => ({
		width: 'auto',
		maxWidth: '100%',
		height: '100%',
		margin: 'auto',

		[theme.breakpoints.down('md')]: {
			height: 'auto',
			width: 'auto'
		}
	}));

	const SwipeableControls = styled(MobileStepper)(() => ({
		width: '100%',
		padding: 0,
		color: ctaTextLightColor,
		background: 'transparent',

		'& > .MuiMobileStepper-dots': {
			position: 'absolute',
			left: '50%',
			bottom: 15,
			transform: 'translateX(-50%)',

			'& .MuiMobileStepper-dotActive': {
				backgroundColor: bgColor
			}
		}
	}));

	const ControlButton = styled(Button)(() => ({
		'--transform': 'translateY(-50%)',
		position: 'absolute',
		top: '50%',
		transform: 'var(--transform)',
		minWidth: '0',
		width: '35px',
		height: '35px',
		margin: '0 15px',
		borderRadius: '50%',
		color: ctaTextLightColor,
		backgroundColor: bgColor,
		opacity: 0.7,
		zIndex: 10,
		transition: 'all 100ms ease-in-out',

		'&:hover': { transform: 'var(--transform) scale(1.1)', backgroundColor: bgColor }
	}));

	const defaultImage = `${process.env.PUBLIC_URL}/img/hotel_placeholder.jpeg`;
	const baseUrl = `${process.env.REACT_APP_MEDIA_URL}/media/`;

	const [active, setActive] = useState(index);
	const max = gallery.length;

	const handleNext = () => {
		setActive((prev) => (prev + 1 >= max ? 0 : prev + 1));
	};

	const handleBack = () => {
		setActive((prev) => (prev - 1 < 0 ? max - 1 : prev - 1));
	};

	const handleChange = (step) => {
		setActive(step);
	};

	const CustomBackdrop = (props) => (
		<Backdrop {...props} open={open} onClose={handleClose} sx={{ color: bgColor }}>
			<CircularProgress color='inherit' />
		</Backdrop>
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{ sx: { backgroundColor: 'transparent', maxWidth: '1250px' } }}
			TransitionComponent={Zoom}
			BackdropComponent={CustomBackdrop}
		>
			<DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
				<ControlButton
					onClick={handleClose}
					sx={{ top: 0, right: 0, '--transform': 'translateY(0%)', margin: '15px' }}
				>
					<CloseRoundedIcon color='error' sx={{ fontSize: '2rem' }} />
				</ControlButton>
				<SwipeableViews axis={'x'} index={active} onChangeIndex={handleChange} enableMouseEvents>
					{gallery.map((img, i) => (
						<ImgCard
							key={i}
							loading='lazy'
							component='img'
							alt={img.name?.es || defaultAlt}
							image={img.hash ? `${baseUrl}${img.hash}?format=webp` : defaultImage}
						/>
					))}
				</SwipeableViews>
				{max > 1 ? (
					<SwipeableControls
						variant='dots'
						steps={max}
						position='static'
						activeStep={active}
						nextButton={
							<ControlButton onClick={handleNext} sx={{ right: 0 }}>
								<ArrowForwardIosRoundedIcon />
							</ControlButton>
						}
						backButton={
							<ControlButton onClick={handleBack} sx={{ left: 0 }}>
								<ArrowBackIosRoundedIcon />
							</ControlButton>
						}
					/>
				) : (
					<></>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default EstablishmentCardGalleryDialog;

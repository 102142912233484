import { useEffect, useState } from 'react';
import { AvailabilityContext } from '../../contexts/AvailabilityContext';
import { UserContext } from '../../contexts/UserContext';
import Literal from './Literal';

import { Box, styled, Typography, MenuItem, Select } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { useContextSelector } from 'use-context-selector';

function EstablishmentOrderSelector({ dataTheme, direction, style }) {
	const labelTextColor = dataTheme.others.colorTitleShowMore;

	const customSelectStyle = {
		position: 'absolute',
		bottom: '0',
		visibility: 'hidden'
	};
	customSelectStyle[direction || 'left'] = 0;
	const CustomSelect = styled(Select)(() => customSelectStyle);

	const getSorting = useContextSelector(AvailabilityContext, (s) => s.getSorting);
	const setSorting = useContextSelector(AvailabilityContext, (s) => s.setSorting);
	const lang = useContextSelector(UserContext, (s) => s.lang);

	const sortingEnum = {
		category_desc: 'sortByCategory',
		category_asc: 'sortByCategory',
		'name.{lang}_desc': 'sortByName',
		'name.{lang}_asc': 'sortByName'
	};
	sortingEnum.length = Object.keys(sortingEnum).length;
	const defaultSorting = sortingEnum.length > 1 ? sortingEnum.length - 1 : 0;

	const [isFirstLoad, setFirstLoad] = useState(true);
	const [isOpen, setOpen] = useState(false);
	const [selected, setSelected] = useState(
		getSorting('orderBy') || Object.keys(sortingEnum)[defaultSorting]
	);
	const [ascDesc, setAscDesc] = useState('');

	useEffect(() => {
		if (!isFirstLoad || !!getSorting('orderBy')) return;
		const split = selected.split('_');
		setAscDesc(split[split.length - 1]);
		const sorting = getSorting();
		sorting.orderBy = selected.replaceAll('{lang}', lang);
		setSorting({ ...sorting });
		return () => setFirstLoad(false);
	}, [isFirstLoad, getSorting, setSorting, selected, lang]);

	// useEffect(() => {
	// 	const orderBy = getSorting('orderBy');
	// 	if (!orderBy || orderBy === selected) return;
	// 	setSelected(getSorting('orderBy'));
	// 	return () => {};
	// }, [selected, getSorting]);

	const handleChange = (e) => {
		const orderBy = e.target.value;
		if (!Object.keys(sortingEnum).includes(orderBy)) return;
		if (getSorting('orderBy') === orderBy) return;
		const split = orderBy.split('_');
		setSelected(orderBy);
		setAscDesc(split[split.length - 1]);
		const parsedOrderBy = orderBy.replaceAll('{lang}', lang);
		setSorting({ orderBy: parsedOrderBy });
	};

	return (
		<Box
			mx={1}
			mb={2}
			position='relative'
			onClick={() => setOpen((prev) => !prev)}
			sx={{
				maxWidth: '45%',
				...style
			}}>
			<Box
				display='flex'
				alignItems='center'
				justifyContent={direction || 'left'}
				gap={1}
				sx={{ color: `${labelTextColor}`, cursor: 'pointer' }}>
				<SortIcon fontSize='small' sx={{ marginTop: '-2.63px', transform: 'scaleX(-1)' }} />
				<Typography
					variant='body1'
					component='p'
					display='block'
					textAlign={direction || 'left'}
					sx={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						'&::first-letter': { textTransform: 'capitalize' }
					}}>
					<Literal lang={lang} text={sortingEnum[selected]} />
					{` ${ascDesc.toLowerCase()}`}
				</Typography>
			</Box>
			<CustomSelect open={isOpen} value={selected} onChange={handleChange}>
				{Object.entries(sortingEnum)
					.filter(([k, v]) => k !== 'length')
					.map(([k, v]) => {
						const ascDesc = k.split('_')[k.split('_').length - 1];
						return (
							<MenuItem value={k} key={k}>
								<Literal lang={lang} text={v} />
								{` ${ascDesc.toLowerCase()}`}
							</MenuItem>
						);
					})}
			</CustomSelect>
		</Box>
	);
}

export default EstablishmentOrderSelector;

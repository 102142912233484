import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, MenuItem, TextField, Typography, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import axios from 'axios';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { createFilterOptions } from '@material-ui/core';
import { translation } from '../../../translations/translationProvider';
import { AvailabilityContext } from '../../../contexts/AvailabilityContext';
import { useContextSelector } from 'use-context-selector';

const _filterOptions = createFilterOptions();
const filterOptions = (props, state) => _filterOptions(props, state).slice(0, 10);

const height = 200;

function Search({ lang, dataTheme, setHeight }) {
	const ctaColor = dataTheme.cta.primary.color;
	const ctaLightColor = dataTheme.cta.light.color;
	const ctaDarkColor = dataTheme.cta.dark.color;
	const borderRadiusRight = dataTheme.others.borderRadiusRight;
	const borderRadiusLeft = dataTheme.others.borderRadiusLeft;
	const colorIcon = dataTheme.iconsInput.color;
	const backgroundIcon = dataTheme.iconsInput.background;

	const ThemeInput = styled(TextField)(() => ({
		backgroundColor: `${ctaLightColor}`,
		borderRadius: `${borderRadiusRight}`,
		'& .MuiInputBase-root': {
			padding: '0 !important',
			width: '100%',
			height: '100%',

			'&:focus': {
				borderColor: `${ctaColor}`,
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		},

		'& .MuiAutocomplete-input': {
			padding: '7.5px 6px 7.5px 6px !important',
			textAlign: 'center',

			'&::placeholder': {
				textTransform: 'uppercase',
				textAlign: 'center',
				fontSize: '0.9rem',
				fontWeight: 500,
				opacity: 1,
				color: `${ctaDarkColor}`
			}
		},

		'& fieldset': {
			border: 'none',
			outline: 'none'
		}
	}));

	const getFilters = useContextSelector(AvailabilityContext, (s) => s.getFilters);
	const filter = getFilters();
	const defaultValue = {
		value: filter.search || filter.destinations || filter[`name.${lang}`] || '',
		type: filter.destinations ? 'destination' : filter[`name.${lang}`] ? 'establishment' : 'mixed'
	};

	const [queryResults, setQueryResults] = useState([]);

	const config = useContextSelector(ConfigContext, (s) => s.config);
	const { ACCESS_TOKEN } = config;

	const API_SEARCH = `${process.env.REACT_APP_API_URL}/client/list`;
	useEffect(() => {
		axios
			.get(`${API_SEARCH}?input=&lang=${lang}`, {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			})
			.then((result) => result.data)
			.then((data) => setQueryResults(data));
		return () => setQueryResults([]);
	}, [API_SEARCH, ACCESS_TOKEN, lang]);

	const [isOpen, setOpen] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	useEffect(() => {
		if (firstLoad) return;
		if (!isOpen) setHeight(0);
		else setHeight(height);
		setFirstLoad(false);
	}, [isOpen, firstLoad, setHeight]);

	const inputTypeRef = useRef();

	return (
		<>
			<SearchIcon
				sx={{
					backgroundColor: `${backgroundIcon}`,
					color: `${colorIcon}`,
					borderRadius: `${borderRadiusLeft}`,
					fontSize: '1.8rem',
					padding: '10px',
					height: '1em'
				}}
			/>

			<input type='hidden' name='searchType' ref={inputTypeRef} value={defaultValue.type} />

			<Box
				sx={{
					minHeight: '100%',
					width: '100%',
					position: 'relative',
					overflow: 'hidden'
				}}>
				<Autocomplete
					defaultValue={defaultValue}
					freeSolo
					openOnFocus
					autoHighlight
					filterSelectedOptions
					filterOptions={filterOptions}
					options={queryResults}
					getOptionLabel={(option) => option.value}
					isOptionEqualToValue={(option, { value }) => option.value === value}
					onClick={() => setOpen((prev) => !prev)}
					onInputChange={(e, nv) => {
						if (!inputTypeRef?.current) return;
						const found = queryResults.find((r) => r.value === nv);
						inputTypeRef.current.value = found?.type || 'mixed';
					}}
					renderInput={(params) => (
						<ThemeInput
							{...params}
							placeholder={translation(lang, 'chooseDestination')}
							name='search'
							sx={{ height: '100%', width: '100%' }}
						/>
					)}
					renderOption={(props, option) => (
						<MenuItem {...props} component={'li'} key={option.value} sx={{ px: 1 }}>
							{option.type === 'destination' ? (
								<LocationOnRoundedIcon fontSize='small' color='inherit' sx={{ pr: 1 }} />
							) : (
								<HotelRoundedIcon fontSize='small' sx={{ pr: 1 }} />
							)}
							<Typography overflow={'hidden'} textOverflow={'ellipsis'}>
								{option.value}
							</Typography>
						</MenuItem>
					)}
					sx={{ height: '100%' }}
				/>
			</Box>
		</>
	);
}

export default Search;

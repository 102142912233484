import { Helmet } from 'react-helmet';
import { ReactComponent as IconSVG } from './assets/icon_house_2.svg';

import { Button, styled } from '@mui/material';

export default function WidgetButton({ theme, user }) {
	const ctaColor = theme.cta.primary.color;
	const ctaTextColor = theme.cta.primary.textColor;
	const ctaLightColor = theme.cta.light.color;
	const ctaBorderRadius = theme.cta.borderRadius;
	const ctaHoverSearch = theme.others.ctaHoverColor;

	const ThemeButton = styled(Button)(() => ({
		position: 'absolute',
		display: 'flex',
		gap: '10px',
		width: '100%',
		minWidth: '50px',
		height: '100%',
		maxHeight: 'none',
		padding: '11px',
		color: `${ctaTextColor}`,
		boxShadow: 'none',
		'&:hover': {
			color: `${ctaLightColor}`,
			backgroundColor: `${ctaHoverSearch}`,
			border: 'none'
		},
		border: 'none',
		borderRadius: `${ctaBorderRadius}`,
		backgroundColor: `${ctaColor}`
	}));

	return (
		<>
			<Helmet>
				<title>{`${user.title['es'] || 'Ibblue'} - IBB Button`}</title>
			</Helmet>
			<ThemeButton variant='contained' color='primary' onClick={() => window.open('/', '_blank')}>
				<IconSVG height={'100%'} width={'100%'} />
			</ThemeButton>
		</>
	);
}

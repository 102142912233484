import { Typography } from '@mui/material';
import { ReactComponent as LogoSVG } from './../../assets/logo.svg';

export default function PoweredBy({ style }) {
	return (
		<a
			href='http://www.ibblue.com'
			target={'_blank'}
			rel='noopener noreferrer'
			onMouseEnter={({ currentTarget }) => {
				currentTarget.style.setProperty('--hover-saturation', '100%');
			}}
			onMouseLeave={({ currentTarget }) => {
				currentTarget.style.setProperty('--hover-saturation', '0%');
			}}
			style={{
				'--hover-saturation': '0%',
				display: 'flex',
				gap: '5px',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'right',
				width: '100%',
				color: 'rgb(168, 168, 167)',
				textDecoration: 'none',
				...style
			}}>
			<Typography component={'span'} sx={{ fontSize: style.fontSize || '0.75em' }}>
				Powered by
			</Typography>
			<LogoSVG
				style={{
					width: 'auto',
					height: '17px',
					filter: 'saturate(var(--hover-saturation))',
					transition: 'all ease-in-out 100ms'
				}}
				aria-label={'IBBLUE'}
			/>
		</a>
	);
}

import {
	Button,
	ClickAwayListener,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Paper,
	Popper,
	styled
} from '@mui/material';
import React from 'react';
import { translation } from '../../translations/translationProvider';
import { UserContext } from '../../contexts/UserContext';
import Literal from './Literal';
import { useContextSelector } from 'use-context-selector';

function ExternalLinkWarning({
	theme,
	resolve,
	reject,
	onClickAway,
	opened,
	anchorEl,
	popperProps
}) {
	const colorIcon = theme.iconsInput.color;
	const backgroundIcon = theme.iconsInput.background;
	const borderRadius = theme.borderRadius;
	const ctaLightHoverColor = theme.others.ctaHoverColor;
	const ctaLightColor = theme.cta.dark.hover;

	const lang = useContextSelector(UserContext, (s) => s.lang);
	const user = useContextSelector(UserContext, (s) => s.user);

	const ButtonCard = styled(Button)(() => ({
		borderRadius: `${borderRadius}`,
		'&:hover, &:focus': {
			backgroundColor: `${ctaLightColor}`
		}
	}));

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<Popper
				disablePortal
				anchorEl={anchorEl}
				open={opened}
				modifiers={[
					{
						name: 'flip',
						enabled: true,
						options: {
							altBoundary: true,
							rootBoundary: 'document',
							padding: 8
						}
					},
					{
						name: 'preventOverflow',
						enabled: true,
						options: {
							altAxis: true,
							altBoundary: true,
							tether: true,
							rootBoundary: 'document',
							padding: 8
						}
					},
					{
						name: 'arrow',
						enabled: true
					}
				]}
				{...popperProps}>
				<Paper sx={{ width: 400, maxWidth: '90%', textAlign: 'left' }}>
					<DialogTitle sx={{ paddingBottom: 0.75 }}>
						<Literal lang={lang} text='externalSiteWarnTitle' />
					</DialogTitle>
					<DialogContent sx={{ maxHeight: 60 }}>
						<DialogContentText>
							{translation(lang, 'externalSiteWarn').replace(/\${client}/gm, user.title[lang])}
						</DialogContentText>
					</DialogContent>
					<Divider />
					<DialogActions>
						<ButtonCard
							sx={{
								backgroundColor: `${colorIcon}`,
								color: `${backgroundIcon}`
							}}
							onClick={reject}>
							<Literal lang={lang} text='cancel' />
						</ButtonCard>
						<ButtonCard
							variant='contained'
							sx={{
								backgroundColor: `${backgroundIcon}`,
								color: `${colorIcon}`,
								'&:hover, &:focus': {
									backgroundColor: `${ctaLightHoverColor}`
								}
							}}
							onClick={resolve}>
							<Literal lang={lang} text='continue' />
						</ButtonCard>
					</DialogActions>
				</Paper>
			</Popper>
		</ClickAwayListener>
	);
}

export default ExternalLinkWarning;

import { Box, Link } from '@mui/material';

function Footer({ theme }) {
	const primaryColor = theme.colors.primary.color;
	const primaryTextColor = theme.colors.primary.textColor;

	const copyrightStartYear = '2021';
	const year = new Date().getFullYear();

	return (
		<Box
			component={'footer'}
			sx={{
				display: 'flex',
				gap: '5px',
				flexWrap: 'wrap',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100px',
				color: primaryTextColor,
				backgroundColor: primaryColor
			}}>
			{`Copyright © ${copyrightStartYear} - ${year} | By`}
			<Link href='https://www.eturistic.com' color='inherit' underline='always' target={'_blank'}>
				{'Eturistic'}
			</Link>
			{'| All rights reserved'}
		</Box>
	);
}

export default Footer;

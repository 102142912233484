import React from 'react';
import { UserContext } from '../../contexts/UserContext';
import { getAvailableLanguages } from '../../translations/translationProvider';
import Logo from '../utilities/Logo';
import Menu from './Menu';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Box, FormControl, Select, MenuItem } from '@mui/material/';
import { useContextSelector } from 'use-context-selector';

function Navbar({ logo, title, web, clientLanguages, dataTheme }) {
	const { showMenu, colors, borderRadius } = dataTheme;
	const primaryColor = colors.primary.color;
	const textColor = colors.primary.textColor;

	const breakpoints = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 900
			}
		}
	});
	const CustomNav = styled(Box)(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '50px 0 110px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 0 100px 0'
		}
	}));
	const CustomSelect = styled(Select)(() => ({
		height: '50px',
		borderRadius: `${borderRadius}`,
		color: `${textColor}`,
		'& *': {
			border: 'none !important',
			color: `${textColor}`,
			fill: `${textColor}`
		}
	}));

	const lang = useContextSelector(UserContext, (s) => s.lang);
	const setLanguage = useContextSelector(UserContext, (s) => s.setLanguage);
	const availableLanguages = getAvailableLanguages();

	const handleLanguageChange = (e) => setLanguage(e.target.value);

	return (
		<Box component={'nav'} backgroundColor={primaryColor}>
			<Box className='container'>
				<ThemeProvider theme={breakpoints}>
					<CustomNav component={'nav'} className='nav-bar'>
						<Logo logo={logo.src} width={logo.width} height={logo.height} title={title} web={web} />
						{showMenu ? <Menu dataTheme={dataTheme} /> : <></>}
						<FormControl>
							<CustomSelect value={lang} onChange={handleLanguageChange} autoWidth>
								{Object.entries(availableLanguages)
									.filter(([key]) => !clientLanguages || clientLanguages.includes(key))
									.map(([value, text], i) => (
										<MenuItem key={i} value={value}>
											{text}
										</MenuItem>
									))}
							</CustomSelect>
						</FormControl>
					</CustomNav>
				</ThemeProvider>
			</Box>
		</Box>
	);
}

export default Navbar;

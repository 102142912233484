import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Outlet, Router } from 'react-router';
import { Helmet } from 'react-helmet';
import './assets/css/normalize.css';
import './assets/css/layout.css';
import getTheme from './themes/themeLoader';
import { getDefaultLanguage, isValidLanguage } from './translations/translationProvider';

import { AvailabilityContext } from './contexts/AvailabilityContext';
import { UserContext } from './contexts/UserContext';

import Main from './components/sections/Main';
import BookingSearch from './components/sections/BookingSearch/BookingSearch';
import WidgetButton from './components/widgets/WidgetIcon';

import { Box } from '@mui/material';
import Base64 from './utils/Base64';
import { useContextSelector } from 'use-context-selector';

// Theme Config
const { user, theme } = getTheme();

function App() {
	const [language, setLanguage] = useState(getDefaultLanguage());
	const setLanguageToCookie = (lang) => {
		const d = new Date();
		d.setTime(d.getTime() + 31 * 24 * 60 * 60 * 1000);
		document.cookie = `lang=${lang};expires=${d.toUTCString()};path=/`;
		setLanguage(lang);
	};

	const [search, setSearch] = useState({ adults: [{ age: 35 }, { age: 35 }], children: [] });
	const [filters, setAvailabilityFilters] = useState({});
	const [sorting, setAvailabilitySorting] = useState({});
	const [isSearching, setSearching] = useState(false);

	const getSearch = useCallback((target) => (!target ? search : search[target]), [search]);

	const getFilters = useCallback((target) => (!target ? filters : filters[target]), [filters]);
	const setFilters = (newFilters = {}) => {
		if (Object.keys(newFilters).length === 0) return setAvailabilityFilters({});
		if (JSON.stringify({ ...filters, ...newFilters }) === JSON.stringify(filters)) return;
		setAvailabilityFilters((prev) => {
			const allFilters = { ...prev, ...newFilters };
			const filtersToSave = {};
			Object.entries(allFilters)
				.filter(([k, v]) => !!v || (Array.isArray(v) && v.length > 0))
				.forEach(([k, v]) => (filtersToSave[k] = v));
			return filtersToSave;
		});
	};

	const getSorting = useCallback((target) => (!target ? sorting : sorting[target]), [sorting]);
	const setSorting = (newSorting = {}) => {
		if (JSON.stringify(newSorting) === JSON.stringify(sorting)) return;
		setAvailabilitySorting(newSorting);
	};

	return (
		<UserContext.Provider value={{ lang: language, setLanguage: setLanguageToCookie, user }}>
			<AvailabilityContext.Provider
				value={{
					getSearch,
					setSearch,
					getFilters,
					setFilters,
					getSorting,
					setSorting,
					isSearching,
					setSearching
				}}>
				<Router location={'es'}>
					<Routes>
						<Route path='/' element={<Content />}>
							<Route path=':search' element={<Outlet />} />
						</Route>
						<Route path='/widgets/form' element={<WidgetForm />} />
						<Route path='/widgets/button' element={<WidgetButton theme={theme} user={user} />} />
					</Routes>
				</Router>
			</AvailabilityContext.Provider>
		</UserContext.Provider>
	);
}

function Content() {
	const backgroundColor = theme.colors['background-page'];

	const setFilters = useContextSelector(AvailabilityContext, (s) => s.setFilters);
	const setSorting = useContextSelector(AvailabilityContext, (s) => s.setSorting);
	const setSearch = useContextSelector(AvailabilityContext, (s) => s.setSearch);
	const setSearching = useContextSelector(AvailabilityContext, (s) => s.setSearching);
	const [searchParamsURL, setSearchParamsURL] = useSearchParams();

	const [lastSearchParam, setLastSearchParam] = useState({});

	useEffect(() => {
		const search = searchParamsURL.get('s');
		if (!search) return;
		if (JSON.stringify(lastSearchParam) === JSON.stringify(search)) return;
		const query = JSON.parse(Base64.decode(search));
		setSearch(query.search || {});
		setFilters(query.filter || {});
		setSorting(query.sort || {});
		setLastSearchParam(search);
		if (searchParamsURL.get('search') === 'true') {
			searchParamsURL.delete('search');
			setSearchParamsURL(searchParamsURL);
			setSearching(true);
		}
		return () => {};
	}, [
		setFilters,
		setSorting,
		setSearch,
		setSearching,
		searchParamsURL,
		setSearchParamsURL,
		lastSearchParam
	]);

	return (
		<Box sx={{ backgroundColor: `${backgroundColor}` }}>
			<Helmet>
				<meta name='theme-color' content={theme.colors.primary.color || '#2da2eb'} />
				<title>{`${user.title['es'] || 'Ibblue'} - IBB MetaSearch`}</title>
			</Helmet>
			<Main theme={theme} user={user} />
		</Box>
	);
}

function WidgetForm(destination) {
	const lang = useContextSelector(UserContext, (s) => s.lang);
	const setLanguage = useContextSelector(UserContext, (s) => s.setLanguage);

	const [searchParamsURL] = useSearchParams();
	const [langParam, setLangParam] = useState(searchParamsURL?.get('lang') || '');

	useEffect(() => {
		if (!langParam) return;
		if (langParam === lang) return;
		if (!isValidLanguage(langParam)) return;
		setLanguage(langParam);
		setLangParam('');
		return () => {};
	}, [langParam, lang, setLanguage, setLangParam]);

	return (
		<>
			<Helmet>
				<title>{`${user.title['es'] || 'Ibblue'} - IBB Form`}</title>
			</Helmet>
			<BookingSearch dataTheme={theme} widget={true} user={user} destination={destination} />
		</>
	);
}

export default App;

import { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDayjs';
import {
	DateRangePicker,
	DateRangePickerDay as MuiDateRangePickerDay,
	LocalizationProvider
} from '@mui/lab';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/de';
import 'dayjs/locale/ca';
import 'dayjs/locale/fr';

function CalendarReservation({
	checkinCheckout,
	setCheckinCheckout,
	setCalendarVisible,
	DateInputBox,
	lang,
	theme
}) {
	const primaryColor = theme.cta.primary.color;
	const textColor = theme.cta.primary.textColor;
	const lightColor = theme.cta.light.color;
	const lightTextColor = theme.cta.light.textColor;
	const ctaDarkColor = theme.cta.dark.color;
	const borderRadiusRight = theme.others.borderRadiusRight;

	const DateRangePickerDay = styled(MuiDateRangePickerDay)(
		({
			isHighlighting,
			isStartOfHighlighting,
			isEndOfHighlighting,
			outsideCurrentMonth,
			selected
		}) => ({
			...(selected && {
				'& .Mui-selected, & .Mui-disabled': {
					backgroundColor: primaryColor + ' !important',
					color: textColor + ' !important',
					'&:hover, &:focus': {
						backgroundColor: primaryColor,
						color: textColor + ' !important'
					}
				}
			}),
			...(!outsideCurrentMonth &&
				isHighlighting && {
					borderRadius: 0,
					backgroundColor: lightColor,
					color: lightTextColor
				}),
			...(isStartOfHighlighting && {
				borderTopLeftRadius: '50%',
				borderBottomLeftRadius: '50%'
			}),
			...(isEndOfHighlighting && {
				borderTopRightRadius: '50%',
				borderBottomRightRadius: '50%'
			})
		})
	);

	const [value, setValue] = useState(checkinCheckout);
	const [open, setOpen] = useState(false);
	const [firstOpen, setFirstOpen] = useState(true);

	useEffect(() => {
		if (firstOpen) {
			setFirstOpen(false);
			setOpen(true);
			return;
		}
		if (open) return;

		setCalendarVisible(false);
		setCheckinCheckout(value);
	}, [firstOpen, open, value, setCheckinCheckout, setCalendarVisible]);

	const dateInputCheckinRef = useRef();
	const dateInputCheckoutRef = useRef();
	useEffect(() => {
		const checkinInput = dateInputCheckinRef.current;
		if (checkinInput && value[0]) {
			checkinInput.innerText = value[0].format(checkinInput.dataset.dateformat);
		}
		const checkoutInput = dateInputCheckoutRef.current;
		if (checkoutInput && value[1]) {
			checkoutInput.innerText = value[1].format(checkoutInput.dataset.dateformat);
		}
	}, [value]);

	return (
		<LocalizationProvider dateAdapter={DateAdapter} locale={lang}>
			<DateRangePicker
				startText='Check-in'
				endText='Check-out'
				value={checkinCheckout}
				open={open}
				minDate={dayjs()}
				shouldDisableDate={(day) => !!value[0] && value[0].isSame(day)}
				onChange={(value) => setValue(value)}
				onClose={() => setOpen(false)}
				className='search_calendar'
				renderDay={(date, props) => <DateRangePickerDay {...props} />}
				disablePast
				renderInput={() => (
					<DateInputBox
						inputsRef={{
							checkin: dateInputCheckinRef,
							checkout: dateInputCheckoutRef
						}}
						checkin={checkinCheckout[0]}
						checkout={checkinCheckout[1]}
						theme={{ ctaDarkColor, borderRadiusRight }}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}

export default CalendarReservation;

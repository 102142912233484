import ibblue from './ibblue.json';
import visitManacor from './visitmanacor.json';
import visitSoller from './visitsoller.json';
import visitCalamillor from './visitcalamillor.json';
import visitaChiclana from './visitachiclana.json';
import fehm from './fehm.json';
import formentera from './formentera.json';

const getTheme = (theme) => {
	const host = window.location.host;
	const subdomain = host?.split('.')[0];
	switch (theme || subdomain) {
		case 'visitsoller':
			return visitSoller;
		case 'visitmanacor':
			return visitManacor;
		case 'visitcalamillor':
			return visitCalamillor;
		case 'visitachiclana':
			return visitaChiclana;
		case 'fehm':
			return fehm;
		case 'formentera':
			return formentera;
		default:
			return ibblue;
	}
};

export { getTheme as default };

import { memo, useCallback, useEffect, useState } from 'react';
import { createTheme, ThemeProvider, Box, Button, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Literal from '../utilities/Literal';
import { UserContext } from '../../contexts/UserContext';
import {
	useAvailability,
	useEstablishmentOrder,
	useIsLoading
} from '../../contexts/AvailabilityPriceContext';
import ExternalLinkWarning from '../utilities/ExternalLinkWarning';
import { useContextSelector } from 'use-context-selector';

const EstablishmentPrice = ({ id, dataTheme, parentRef }) => {
	const getAvailability = useAvailability();
	const getEstablishmentOrder = useEstablishmentOrder();
	const isLoading = useIsLoading();
	const availability = getAvailability(id)?.availability;

	const lang = useContextSelector(UserContext, (s) => s.lang);

	const borderRadius = dataTheme.borderRadius;
	const ctaColor = dataTheme.cta.primary.color;
	const errorColor = dataTheme.colors.error;
	const ctaTextColor = dataTheme.cta.primary.textColor;
	const ctaHoverPrice = dataTheme.others.ctaHoverColor;

	//Themes
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 480,
				sm: 700,
				md: 900,
				lg: 1200,
				xl: 1536
			}
		}
	});

	const ButtonCard = styled(Button)(() => {
		return {
			width: '100%',
			backgroundColor: `${!!availability?.minPrice ? ctaColor : errorColor.color}`,
			color: `${!!availability?.minPrice ? ctaTextColor : errorColor.textColor}`,
			borderRadius: `${borderRadius}`,
			fontSize: '1rem',
			fontWeight: '400',
			textTransform: 'uppercase',
			lineHeight: 1.5,
			boxShadow: 'none',
			padding: '10px 15px',
			minWidth: '130px',
			whiteSpace: 'nowrap',

			'&:hover': {
				fontWeight: '500',
				color: `${!!availability?.minPrice ? ctaTextColor : errorColor.textColor}`,
				backgroundColor: `${!!availability?.minPrice ? ctaHoverPrice : errorColor.color}`
			}
		};
	});
	//

	useEffect(() => {
		if (!availability || !parentRef.current) return;
		parentRef.current.style.order = getEstablishmentOrder(id);
	}, [id, parentRef, availability, getEstablishmentOrder]);

	const [externalLinkEl, setExternalLinkEl] = useState(null);

	const handleClick = useCallback(() => {
		if (!availability) return;
		const url = `${process.env.REACT_APP_SECURE_TRACK_URL}/url/availability/${availability?.trackerData?.hash}${availability?.trackerData?.params}`;
		if (!!availability.minPrice) window.open(url, '_blank');
	}, [availability]);

	return (
		<>
			{isLoading ? (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress
						loading={(!!availability?.minPrice).toString()}
						sx={{ color: `${ctaColor}` }}
					/>
				</Box>
			) : (
				<ThemeProvider theme={theme}>
					<ButtonCard
						size='medium'
						className='establishment-price'
						sx={{ display: 'flex', margin: '0 auto' }}
						onClick={(e) =>
							setExternalLinkEl(
								e.currentTarget.parentElement.parentElement.parentElement.parentElement
									.parentElement
							)
						}>
						{!!availability?.minPrice ? (
							`${availability.minPrice}€`
						) : (
							<Literal lang={lang} text='noAvailability' />
						)}
					</ButtonCard>
					{Boolean(externalLinkEl) && (
						<ExternalLinkWarning
							theme={dataTheme}
							resolve={handleClick}
							reject={() => setExternalLinkEl(null)}
							onClickAway={() => setExternalLinkEl(null)}
							anchorEl={externalLinkEl}
							opened={Boolean(externalLinkEl)}
							popperProps={{ placement: 'bottom-end' }}
						/>
					)}
				</ThemeProvider>
			)}
		</>
	);
};

function areEqual(prev, next) {
	return JSON.stringify(prev) === JSON.stringify(next);
}

export default memo(EstablishmentPrice, areEqual);

import { useState, useEffect, useRef, useCallback, memo, useMemo } from 'react';
import { AvailabilityContext } from '../../contexts/AvailabilityContext';
import { ConfigContext } from '../../contexts/ConfigContext';
import axios from 'axios';

import EstablishmentCard from '../utilities/EstablishmentCard';
import EstablishmentSkeleton from '../utilities/EstablishmentSkeleton';
import EstablishmentOrderSelector from '../utilities/EstablishmentOrderSelector';
import BookingSearchFilters from '../utilities/BookingSearchFilters';
import NoEstablishmentsFound from '../utilities/NoEstablishmentsFound';
import { Box } from '@mui/material';
import { useContextSelector } from 'use-context-selector';
// import Banner from './Banner/Banner.jsx';

function EstablishmentList({ dataTheme, isFiltersOpened, openFilters }) {
	// Availability Context
	const getFilters = useContextSelector(AvailabilityContext, (s) => s.getFilters);
	const getSorting = useContextSelector(AvailabilityContext, (s) => s.getSorting);

	const [isLoading, setLoading] = useState(false);
	const [isFirstLoad, setFirstLoad] = useState(true);
	const [nextPage, setNextPage] = useState(1);
	const [establishments, setEstablishments] = useState([]);
	const [activeEstablishments, setActiveEstablishments] = useState([]);

	const [loadEstablishments, setLoadEstablishments] = useState(false);

	// CONFIG
	const config = useContextSelector(ConfigContext, (s) => s.config);
	const [ACCESS_TOKEN] = useState(config.ACCESS_TOKEN);
	const [GET_ESTABLISHMENTS_PATH] = useState(config.GET_ESTABLISHMENTS_PATH);
	const BASE_API_URL = process.env.REACT_APP_API_URL;

	const containerRef = useRef();

	const getFetchEstablishments = useCallback(
		(page = 1, args = {}) => {
			const authToken = {
				headers: { Authorization: `Bearer ${ACCESS_TOKEN}` }
			};
			const filterURI = Object.entries(args.filter || getFilters())
				.map(([k, v]) => `filter.${k}=${encodeURIComponent(String(v))}`)
				.join('&');
			const sortingURI = Object.entries(args.sorting || getSorting())
				.map(([k, v]) => `sort.${k}=${encodeURIComponent(v)}`)
				.join('&');
			return axios.get(
				`${BASE_API_URL}${GET_ESTABLISHMENTS_PATH}?page=${page}${filterURI ? `&${filterURI}` : ''}${
					sortingURI ? `&${sortingURI}` : ''
				}`,
				authToken
			);
		},
		[BASE_API_URL, ACCESS_TOKEN, GET_ESTABLISHMENTS_PATH, getFilters, getSorting]
	);

	const getEstablishments = useCallback(
		(options = {}) => {
			let establishmentList = [...activeEstablishments, ...establishments];
			return establishmentList;
		},
		[activeEstablishments, establishments]
	);

	const addEstablishments = useCallback((newEstablishments = [], loadedEstablishments) => {
		if (newEstablishments.length === 0) return;
		const activeEstablishmentList = [],
			establishmentList = [];
		newEstablishments.forEach((e) => {
			if (loadedEstablishments.some((fe) => fe._id === e._id)) return;
			if (e.engine === 'on') activeEstablishmentList.push(e);
			else establishmentList.push(e);
		});
		if (activeEstablishmentList.length > 0)
			setActiveEstablishments((prev) => [...prev, ...activeEstablishmentList]);
		if (establishmentList.length > 0) setEstablishments((prev) => [...prev, ...establishmentList]);
	}, []);

	useEffect(() => {
		if (!loadEstablishments || isLoading) return;
		setLoading(true);
		getFetchEstablishments(nextPage)
			.then((response) => {
				addEstablishments(response.data.establishments, getEstablishments());
				setNextPage(response.data.next);
				setLoading(false);
			})
			.catch((err) => console.error(err));
		return () => {
			setLoadEstablishments(false);
		};
	}, [
		loadEstablishments,
		isLoading,
		nextPage,
		getFetchEstablishments,
		getEstablishments,
		addEstablishments
	]);

	useEffect(() => {
		setEstablishments([]);
		setActiveEstablishments([]);
		setNextPage(1);
		setLoadEstablishments(true);
		return () => {};
	}, [getFilters, getSorting]);

	useEffect(() => {
		if (!isFirstLoad) return;
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setLoadEstablishments(true);
		return () => setFirstLoad(false);
	}, [isFirstLoad]);

	useEffect(() => {
		const scrollHandler = (e) => {
			const containerDiv = containerRef.current;
			const offset =
				containerDiv.getBoundingClientRect().top -
				containerDiv.offsetParent.getBoundingClientRect().top;
			const top = Math.ceil(window.pageYOffset + window.innerHeight - offset);
			if (top < containerDiv.scrollHeight || !nextPage || isLoading) return;
			setLoadEstablishments(true);
		};

		window.addEventListener('scroll', scrollHandler, { passive: false });

		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, [nextPage, isLoading, ACCESS_TOKEN, GET_ESTABLISHMENTS_PATH]);

	const establishmentSkeleton = useMemo(
		() =>
			isLoading ? (
				<>
					<EstablishmentSkeleton dataTheme={dataTheme} />
					<EstablishmentSkeleton dataTheme={dataTheme} />
				</>
			) : (
				<></>
			),
		[isLoading, dataTheme]
	);

	const establishmentCards = useMemo(() => {
		const establishmentList = getEstablishments({ sorted: true, filtered: true });
		if (!isLoading && establishmentList.length === 0)
			return <NoEstablishmentsFound theme={dataTheme} />;
		return establishmentList.map((establishment, i) => (
			<EstablishmentCard
				key={establishment._id}
				establishment={establishment}
				lazyLoad={i > 4}
				dataTheme={dataTheme}
			/>
		));
	}, [isLoading, dataTheme, getEstablishments]);

	return (
		<Box mt={5} mb={5} ref={containerRef}>
			{/* <Banner dataTheme={dataTheme} /> */}
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<EstablishmentOrderSelector
					style={{ display: 'inline-block', float: 'left' }}
					direction='left'
					dataTheme={dataTheme}
				/>
				<BookingSearchFilters
					style={{ display: 'inline-block' }}
					direction='right'
					dataTheme={dataTheme}
					onClick={() => openFilters(!isFiltersOpened)}
				/>
			</Box>
			<Box content={'section'} display='flex' flexDirection='column' gap={4}>
				{establishmentCards}
				{establishmentSkeleton}
			</Box>
		</Box>
	);
}

function areEqual(prev, next) {
	return JSON.stringify(prev) === JSON.stringify(next);
}

export default memo(EstablishmentList, areEqual);

import { useState, useEffect } from 'react';
import { AvailabilityContext } from '../../contexts/AvailabilityContext';
import { UserContext } from '../../contexts/UserContext';

import { Box, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { translation } from '../../translations/translationProvider';
import { camelCase } from './Literal';
import { useContextSelector } from 'use-context-selector';

function BookingSearchFilters({ dataTheme, direction, style, onClick }) {
	const labelTextColor = dataTheme.others.colorTitleShowMore;

	const lang = useContextSelector(UserContext, (s) => s.lang);
	const getFilters = useContextSelector(AvailabilityContext, (s) => s.getFilters);

	const [text, setText] = useState('');

	useEffect(() => {
		const filters = getFilters();
		if (Object.keys(filters) === 0) return;
		const provisionalText = [];
		Object.entries(filters).forEach(([k, v]) => {
			if (!v || (Array.isArray(v) && v.length < 1)) return;
			if (Array.isArray(v)) v = v.map((e) => translation(lang, camelCase((e || '').toLowerCase())));
			provisionalText.push(
				`${(translation(lang, k.split('.')[0]) || '').toLowerCase()}=${String(v)}`
			);
		});
		if (provisionalText.length === 0)
			provisionalText.push((translation(lang, 'addFilters') || '').toLowerCase());
		setText(provisionalText.join('; '));
	}, [lang, getFilters]);

	return (
		<Box
			mx={1}
			mb={2}
			position='relative'
			sx={{
				maxWidth: '50%',
				...style
			}}
			onClick={onClick}>
			<Box
				display='flex'
				alignItems='center'
				justifyContent={direction || 'left'}
				gap={0.7}
				sx={{ color: `${labelTextColor}`, cursor: 'pointer' }}>
				<FilterAltIcon fontSize='small' sx={{ marginTop: '-2.5px' }} />
				<Typography
					variant='body1'
					component='p'
					display='block'
					textAlign={direction || 'left'}
					sx={{
						alignItems: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						'&::first-letter': { textTransform: 'capitalize' }
					}}>
					{`${translation(lang, 'filters')}: ${text}`}
				</Typography>
			</Box>
		</Box>
	);
}

export default BookingSearchFilters;

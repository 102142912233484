import React, { useCallback, useState } from 'react';

import { Box, Link, List, ListItem, SwipeableDrawer } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function Menu({ dataTheme }) {
	const primaryColor = dataTheme.colors.primary.color;
	const primaryTextColor = dataTheme.colors.primary.textColor;
	const secondaryColor = dataTheme.colors.secondary.color;
	const secondaryTextColor = dataTheme.colors.secondary.textColor;
	const colorClose = dataTheme.others.colorClose;
	const hoverLinkColor = dataTheme.others.darkHoverColor;
	const backgroundBody = dataTheme.others.backgroundPage;

	document.body.style.backgroundColor = `${backgroundBody}`;

	//Themes
	const breakpoints = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 480
			}
		}
	});
	const themeIcons = createTheme({
		palette: {
			primary: {
				main: `${primaryColor}`,
				contrastText: `${primaryTextColor}`
			},
			secondary: {
				main: `${secondaryColor}`,
				contrastText: `${secondaryTextColor}`
			},
			close: {
				main: `${colorClose}`
			}
		}
	});
	const CustomLink = styled(Link)(({ theme }) => ({
		color: `${secondaryTextColor}`,
		fontSize: '1.125rem',
		textDecoration: 'none',
		textTransform: 'uppercase',
		padding: '12px 16px',
		width: '100%',
		'&:hover': {
			color: `${hoverLinkColor}`
		},
		[theme.breakpoints.down('sm')]: {
			padding: '20px 16px'
		}
	}));
	const CustomLinkNavBar = styled(Link)(({ theme }) => ({
		color: `${primaryTextColor}`,
		fontSize: '1.125rem',
		textDecoration: 'none',
		textTransform: 'uppercase',
		'&:hover': {
			color: `${hoverLinkColor}`
		}
	}));
	//End Themes

	const [widthNav, setWidthNav] = useState(window.innerWidth);
	window.addEventListener('resize', () => setWidthNav(window.innerWidth));

	const [isOpen, setOpen] = useState(false);

	const toggleDrawer = useCallback(
		(event) => {
			if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
				return;
			setOpen(!isOpen);
		},
		[isOpen, setOpen]
	);

	const items = [
		{ text: 'inicio', url: '/' },
		{
			text: 'Quiénes somos',
			url: 'https://www.ibblue.com/',
			rel: 'noreferrer',
			target: '_blank'
		},
		{
			text: 'Faq',
			url: 'https://www.ibblue.com/#frequently-question',
			rel: 'noreferrer',
			target: '_blank'
		}
	];

	const list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
			role='presentation'
			onClick={toggleDrawer}
		>
			<List>
				<ThemeProvider theme={breakpoints}>
					{items.map(({ text, url, rel, target }, i) => (
						<ListItem key={i}>
							<CustomLink href={url} className='link' rel={rel} target={target}>
								{text}
							</CustomLink>
						</ListItem>
					))}
				</ThemeProvider>
			</List>
		</Box>
	);

	return widthNav < 900 ? (
		<>
			<ThemeProvider theme={themeIcons}>
				<MenuRoundedIcon
					onClick={toggleDrawer}
					className='material-icons'
					color='secondary'
					sx={{ fontSize: '3rem', cursor: 'pointer' }}
				/>
			</ThemeProvider>
			<SwipeableDrawer anchor={'top'} open={isOpen} onClose={toggleDrawer} onOpen={toggleDrawer}>
				{list('top')}
			</SwipeableDrawer>
		</>
	) : (
		<>
			<List sx={{ display: 'flex' }}>
				<ListItem sx={{ padding: '0 10px', width: 'auto' }}>
					<CustomLinkNavBar href='/' className='link'>
						Inicio
					</CustomLinkNavBar>
				</ListItem>
				<ListItem sx={{ padding: '0 10px', width: 'auto' }}>
					<CustomLinkNavBar
						href='https://www.ibblue.com/'
						target='_blank'
						rel='noreferrer'
						className='link'
					>
						Quiénes somos
					</CustomLinkNavBar>
				</ListItem>
				<ListItem sx={{ padding: '0 10px', width: 'auto' }}>
					<CustomLinkNavBar
						href='https://www.ibblue.com/#frequently-question'
						target='_blank'
						rel='noreferrer'
						className='link'
					>
						Faq
					</CustomLinkNavBar>
				</ListItem>
			</List>
		</>
	);
}

export default Menu;

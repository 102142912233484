import React, { useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import EstablishmentCardGalleryDialog from './EstablishmentCardGalleryDialog';

import { Button, CardMedia, Grid, MobileStepper, styled } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

function EstablishmentCardGallery({ gallery, lazy, defaultAlt, theme }) {
	const ctaTextLightColor = theme.colors.secondary.textColor;
	const bgColor = '#fff';

	const ImgGrid = styled(Grid)(({ theme }) => ({
		position: 'relative',
		height: '250px',
		minHeight: '100%',
		maxHeight: '250px',
		padding: '0 !important',
		cursor: 'pointer',
		'& > div, & .react-swipeable-view-container': { height: '100%' },
		'&:hover button': { opacity: 1 },

		[theme.breakpoints.down('md')]: {
			height: '190px',
			minHeight: '0',
			width: '100%'
		}
	}));

	const ImgCard = styled(CardMedia)(({ theme }) => ({
		height: '100%',
		width: '100%',

		[theme.breakpoints.down('md')]: {
			height: '190px'
		}
	}));

	const SwipeableControls = styled(MobileStepper)(() => ({
		width: '100%',
		color: ctaTextLightColor,
		background: 'transparent',

		'& > .MuiMobileStepper-dots': {
			position: 'absolute',
			left: '50%',
			bottom: 15,
			transform: 'translateX(-50%)',

			'& .MuiMobileStepper-dotActive': {
				backgroundColor: bgColor
			}
		}
	}));

	const ControlButton = styled(Button)(() => ({
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		minWidth: '0',
		width: '35px',
		height: '35px',
		margin: '0 15px',
		borderRadius: '50%',
		color: ctaTextLightColor,
		backgroundColor: bgColor,
		opacity: 0.7,

		'&:hover': { backgroundColor: bgColor }
	}));

	const defaultImage = `${process.env.PUBLIC_URL}/img/hotel_placeholder.jpeg`;
	const baseUrl = `${process.env.REACT_APP_MEDIA_URL}/media/`;
	if (gallery.length === 0) gallery.push({});

	const containerRef = useRef(null);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const [active, setActive] = useState(0);
	const [open, setOpen] = useState(false);
	const max = gallery.length;

	const handleNext = () => {
		setActive((prev) => (prev + 1 >= max ? 0 : prev + 1));
	};

	const handleBack = () => {
		setActive((prev) => (prev - 1 < 0 ? max - 1 : prev - 1));
	};

	const handleChange = (step) => {
		setActive(step);
	};

	const handleGalleryOpen = () => {
		setOpen(true);
	};

	const handleGalleryClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!containerRef || !containerRef.current) return;
		if (containerRef.current.clientWidth === width && containerRef.current.clientHeight === height)
			return;
		setWidth(containerRef.current.clientWidth);
		setHeight(containerRef.current.clientHeight);
	}, [width, height, containerRef]);

	return (
		<ImgGrid item md={4} sm={12} xs={12} ref={containerRef}>
			<SwipeableViews axis={'x'} index={active} onChangeIndex={handleChange} enableMouseEvents>
				{(width > 0 && height > 0 ? gallery : []).map((img, i) => {
					const imageSrc = `${baseUrl}${img.hash}?size=${width},${height}`;
					return (
						<picture key={i}>
							{!!img.hash ? (
								<>
									<source type='image/webp' srcSet={`${imageSrc}&format=webp`} />
									<source type='image/jpeg' srcSet={`${imageSrc}&format=jpg`} />
									<source type='image/png' srcSet={`${imageSrc}&format=png`} />
								</>
							) : (
								<></>
							)}
							<ImgCard
								{...(!lazy && i === 0 ? {} : { loading: 'lazy' })}
								component='img'
								alt={img.name?.es || defaultAlt}
								image={img.hash ? undefined : defaultImage}
								onClick={handleGalleryOpen}
							/>
						</picture>
					);
				})}
			</SwipeableViews>
			{max > 1 ? (
				<SwipeableControls
					variant='dots'
					steps={max}
					position='static'
					activeStep={active}
					nextButton={
						<ControlButton onClick={handleNext} sx={{ right: 0 }}>
							<ArrowForwardIosRoundedIcon />
						</ControlButton>
					}
					backButton={
						<ControlButton onClick={handleBack} sx={{ left: 0 }}>
							<ArrowBackIosRoundedIcon />
						</ControlButton>
					}
				/>
			) : (
				<></>
			)}
			{(max === 1 && Object.keys(gallery[0]).length === 0) ? (
				<></>
			) : (
				<EstablishmentCardGalleryDialog
					theme={theme}
					gallery={gallery}
					open={open}
					handleClose={handleGalleryClose}
					index={active}
					defaultAlt={defaultAlt}
				/>
			)}
		</ImgGrid>
	);
}

export default EstablishmentCardGallery;

import {
	Box,
	Button,
	ClickAwayListener,
	FormControl,
	InputBase,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	styled
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import Literal from '../../utilities/Literal';
import { AvailabilityContext } from '../../../contexts/AvailabilityContext';
import { useContextSelector } from 'use-context-selector';

const MIN_ADULTS = 1,
	MAX_ADULTS = 6,
	MIN_CHILDREN = 0,
	MAX_CHILDREN = 4;
const DEFAULT_CHILD_AGE = 12;
const CHILD_MAX_AGE = 17;
const agesArray = [];
for (let i = 1; i <= CHILD_MAX_AGE; i++) agesArray.push(i);

const BoxInputAge = ({ id, age, lang, theme, setChildrenPlaceholder }) => {
	const ThemeSelectAge = styled(InputBase)(() => ({
		'& .MuiInputBase-input': {
			backgroundColor: `${theme.ctaLightColor}`,
			padding: '0 !important',

			'&:focus': {
				borderColor: `${theme.ctaColor}`,
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		},

		'& .MuiSelect-select': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: `${theme.ctaDarkColor}`,
			fontSize: '0.9rem',
			fontWeight: '500',
			textTransform: 'uppercase',
			paddingRight: '0',
			height: '100% !important'
		},
		'& .MuiSelect-nativeInput': {
			pointerEvents: 'unset',
			cursor: 'pointer'
		}
	}));

	const NameFamily = styled('label')(({ theme }) => ({
		fontSize: '1rem',
		fontWeight: '500',

		'&.age-title': {
			fontSize: '1rem',
			width: '100px'
		}
	}));

	const [isOpen, setOpen] = useState(false);
	const [isSelecting, setSelecting] = useState(false);

	const handleOpen = () => {
		if (!isSelecting) {
			setOpen(true);
			setSelecting(true);
		}
	};

	const handleChange = ({ target }) => {
		setOpen(false);
		setChildrenPlaceholder((prev) => {
			prev[id] = { age: target.value };
			return [...prev];
		});
	};

	return (
		<>
			<Box className='dates-family'>
				<NameFamily className='age-title'>
					<Literal lang={lang} text='childAge' />
				</NameFamily>
			</Box>
			<Box className='count-family'>
				<FormControl sx={{ width: '100px' }}>
					<InputLabel
						id={`inputAge_${id}`}
						sx={{
							top: '-12px',
							left: '-7px',
							textTransform: 'uppercase',
							transformOrigin: 'left'
						}}></InputLabel>
					<Select
						labelId={`inputAge_${id}`}
						id={`selectInputAge_${id}`}
						label={<Literal lang={lang} text='childAge' />}
						sx={{ textAlign: 'center', height: '30px' }}
						input={<ThemeSelectAge />}
						defaultValue={age || DEFAULT_CHILD_AGE}
						onChange={handleChange}
						onClick={handleOpen}
						onClose={() => setSelecting(false)}
						open={isOpen}>
						{agesArray.map((age) => (
							<MenuItem key={age} value={age}>
								{age < 2 ? '0 - 1' : age}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</>
	);
};

function Paxes({ theme, lang, setHeight }) {
	const InputCountPeople = styled('label')(() => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: `${theme.ctaLightColor}`,
		color: `${theme.ctaDarkColor}`,
		border: 'none',
		borderRadius: `${theme.borderRadiusRight}`,
		fontSize: '0.9rem',
		fontWeight: '500',
		textTransform: 'uppercase',
		textAlign: 'center',
		height: '100%',
		appearance: 'none',
		cursor: 'pointer'
	}));

	const OperationsFamily = styled('span')(() => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: `${theme.ctaColor}`,
		backgroundColor: `${theme.ctaLightColor}`,
		borderRadius: `${theme.borderRadius}`,
		fontSize: '1.5rem',
		textAlign: 'center',
		height: '35px',
		width: '40px',
		cursor: 'pointer',

		'&:hover': {
			backgroundColor: `${theme.ctaHoverPaxes}`
		}
	}));

	const NameFamily = styled('label')(() => ({
		fontSize: '1rem',
		fontWeight: '500',

		'&.age-title': {
			fontSize: '1rem',
			width: '100px'
		}
	}));

	// Availability Context
	const getSearch = useContextSelector(AvailabilityContext, (s) => s.getSearch);
	const search = getSearch();

	const paxesBox = useRef();

	const btnLessAdults = useRef();
	const btnMoreAdults = useRef();
	const btnLessChildren = useRef();
	const btnMoreChildren = useRef();

	const [isOpen, setOpen] = useState(false);
	const [adults, setAdults] = useState(
		search.adults ? search.adults : [({ age: 35 }, { age: 35 })]
	);
	const [children, setChildren] = useState(search.children ? search.children : []);
	const [adultsPlaceholder, setAdultsPlaceholder] = useState(adults);
	const [childrenPlaceholder, setChildrenPlaceholder] = useState(children);

	const confirmPlaceholders = useCallback(() => {
		setAdults(adultsPlaceholder);
		setChildren(childrenPlaceholder);
	}, [adultsPlaceholder, childrenPlaceholder]);

	const resetPlaceholders = useCallback(() => {
		setAdultsPlaceholder(adults);
		setChildrenPlaceholder(children);
	}, [adults, children]);

	const addInputChild = () => {
		setChildrenPlaceholder((prev) => [...prev, { age: DEFAULT_CHILD_AGE }]);
	};

	const deleteInputChild = () => {
		return setChildrenPlaceholder((prev) => {
			const newArray = [...prev];
			newArray.pop();
			return newArray;
		});
	};

	const [firstLoad, setFirstLoad] = useState(true);
	useEffect(() => {
		if (firstLoad) return;
		if (!isOpen || !paxesBox?.current) setHeight(0);
		else setHeight(paxesBox.current.offsetHeight);
		setFirstLoad(false);
	}, [isOpen, firstLoad, setHeight]);

	useEffect(() => {
		if (!isOpen) return;

		const btnLA = btnLessAdults.current,
			btnMA = btnMoreAdults.current,
			btnLC = btnLessChildren.current,
			btnMC = btnMoreChildren.current;
		// const totalFamily = adults + children;

		const getCSSText = (count, value, max = true) => {
			const gt = max ? count < value : count > value;
			const pe = `pointer-events: ${gt ? 'cursor' : 'none'}`;
			const o = `opacity: ${gt ? '1' : '0.4'}`;
			return `${pe};${o}`;
		};

		const adultsCount = adultsPlaceholder.length;
		const childrenCount = childrenPlaceholder.length;
		btnLA.style.cssText = getCSSText(adultsCount, MIN_ADULTS, false);
		btnMA.style.cssText = getCSSText(adultsCount, MAX_ADULTS);
		btnLC.style.cssText = getCSSText(childrenCount, MIN_CHILDREN, false);
		btnMC.style.cssText = getCSSText(childrenCount, MAX_CHILDREN);
		return () => {};
	}, [adultsPlaceholder, childrenPlaceholder, isOpen]);

	return (
		<>
			<input type='hidden' name='adults' value={JSON.stringify(adults)} />
			<input type='hidden' name='children' value={JSON.stringify(children)} />
			<PeopleIcon
				sx={{
					backgroundColor: `${theme.backgroundIcon}`,
					color: `${theme.colorIcon}`,
					borderRadius: `${theme.borderRadiusLeft}`,
					fontSize: '1.8rem',
					padding: '10px',
					height: '1em'
				}}
			/>

			<ClickAwayListener
				onClickAway={() => {
					setOpen(false);
					confirmPlaceholders();
				}}>
				<Box className='info-people'>
					<input type='checkbox' id='people' name='tab-group-1' />
					<InputCountPeople
						htmlFor='people'
						className='count-people text-uppercase'
						onClick={() => setOpen((prev) => !prev)}>
						{adults.length} <Literal lang={lang} text='adults' /> {children.length}{' '}
						<Literal lang={lang} text='children' />
					</InputCountPeople>
					{isOpen ? (
						<Box className='content' ref={paxesBox}>
							<Box className='family'>
								<Box className='dates-family'>
									<NameFamily>
										<Literal lang={lang} text='adults' />
									</NameFamily>
								</Box>
								<Box className='count-family'>
									<OperationsFamily
										onClick={() =>
											setAdultsPlaceholder((prev) => {
												const newArray = [...prev];
												newArray.pop();
												return newArray;
											})
										}
										ref={btnLessAdults}>
										{'-'}
									</OperationsFamily>
									<p className='num-family'>{adultsPlaceholder.length}</p>
									<OperationsFamily
										component={'span'}
										onClick={() => setAdultsPlaceholder((prev) => [...prev, { age: 35 }])}
										ref={btnMoreAdults}>
										{'+'}
									</OperationsFamily>
								</Box>

								<Box className='dates-family'>
									<NameFamily>
										<Literal lang={lang} text='children' />
									</NameFamily>
								</Box>
								<Box className='count-family'>
									<OperationsFamily
										component={'span'}
										onClick={() => deleteInputChild()}
										ref={btnLessChildren}>
										{'-'}
									</OperationsFamily>
									<p className='num-family'>{childrenPlaceholder.length}</p>
									<OperationsFamily
										component={'span'}
										onClick={() => addInputChild()}
										ref={btnMoreChildren}>
										{'+'}
									</OperationsFamily>
								</Box>

								{childrenPlaceholder.map((child, i) => (
									<BoxInputAge
										key={i}
										id={i}
										age={child.age}
										theme={theme}
										setChildrenPlaceholder={setChildrenPlaceholder}
									/>
								))}

								<Stack flexDirection={'row'} gap={5} mt={1} sx={{ color: theme.ctaColor }}>
									<Button
										color='inherit'
										onClick={() => {
											setOpen(false);
											resetPlaceholders();
										}}>
										<Literal lang={lang} text='cancel' />
									</Button>
									<Button
										color='inherit'
										onClick={() => {
											setOpen(false);
											confirmPlaceholders();
										}}>
										<Literal lang={lang} text='done' />
									</Button>
								</Stack>
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</ClickAwayListener>
		</>
	);
}

export default Paxes;

import { useState, useEffect } from 'react';
import axios from 'axios';

import { ConfigContext } from '../../contexts/ConfigContext';
import { AvailabilityContext } from '../../contexts/AvailabilityContext';
import { UserContext } from '../../contexts/UserContext';
import { defaultLang, translation } from '../../translations/translationProvider';
import Literal, { camelCase } from './Literal';

import {
	styled,
	Box,
	FormControl,
	Grid,
	Rating,
	Button,
	Checkbox,
	FormGroup,
	FormControlLabel
} from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { useContextSelector } from 'use-context-selector';

function FilterForm({ theme, width, handleClose }) {
	const primaryColor = theme.colors.primary.color;
	const primaryTextColor = theme.colors.primary.textColor;
	const ctaDarkColor = theme.cta.dark.color;
	const colorHover = theme.others.ctaHoverColor;
	const borderRadius = theme.borderRadius;

	const InputContainer = styled(Grid)(() => ({
		'&:focus-within > label': {
			fontWeight: 500,
			color: primaryColor
		}
	}));

	const BaseLabel = styled('label')(() => ({
		marginLeft: '3px',
		color: ctaDarkColor
	}));

	const BaseCheckbox = styled(Checkbox)(() => ({
		'&.Mui-checked': { color: primaryColor }
	}));

	const BaseButton = styled(Button)(() => ({
		borderRadius: `${borderRadius}`
	}));

	const config = useContextSelector(ConfigContext, (s) => s.config);
	const { ACCESS_TOKEN } = config;
	const API_TYPES_URL = `${process.env.REACT_APP_API_URL}/client/establishments/types`;
	const API_SERVICES_URL = `${process.env.REACT_APP_API_URL}/client/establishments/services`;

	const getFilters = useContextSelector(AvailabilityContext, (s) => s.getFilters);
	const setFilters = useContextSelector(AvailabilityContext, (s) => s.setFilters);
	const lang = useContextSelector(UserContext, (s) => s.lang);

	const [provisionalFilters, setProvisionalFilters] = useState({});
	const [types, setTypes] = useState([]);
	const [services, setServices] = useState([]);

	const filterList = {
		category: {
			id: 'category',
			labelText: translation(lang, 'category'),
			input: {
				element: (props) => <Rating {...props} />,
				props: {
					precision: 1,
					size: 'large',
					sx: { color: primaryColor },
					onChange: (e) => {
						const value = Number(e.target.value);
						if (value === provisionalFilters[`category`])
							return (provisionalFilters[`category`] = 0);
						provisionalFilters[`category`] = value;
					}
				}
			},
			target: 'category',
			defaultValue: 0,
			cast: (v) => Number(v)
		},
		types: {
			id: 'types',
			labelText: translation(lang, 'types'),
			input: {
				element: (props) => (
					<FormGroup {...props}>
						{types.map((type) => (
							<FormControlLabel
								key={type}
								control={
									<BaseCheckbox
										checked={(provisionalFilters.types || []).includes(type)}
										name={type}
										onChange={({ target }) => handleCheckboxChange('types', type, target.checked)}
									/>
								}
								label={translation(lang, camelCase(type.toLowerCase()))}
							/>
						))}
					</FormGroup>
				),
				props: {
					onChange: () => {}
				}
			},
			target: 'types',
			defaultValue: [],
			cast: (v) => v
		},
		services: {
			id: 'services',
			labelText: translation(lang, 'services'),
			input: {
				element: (props) => (
					<FormGroup {...props}>
						{services.map(({ _id, name }) => (
							<FormControlLabel
								key={_id}
								control={
									<BaseCheckbox
										checked={(provisionalFilters.services || []).includes(_id)}
										name={name[lang] || name[defaultLang]}
										onChange={({ target }) => handleCheckboxChange('services', _id, target.checked)}
									/>
								}
								label={name[lang] || name[defaultLang]}
							/>
						))}
					</FormGroup>
				),
				props: {
					onChange: () => {}
				}
			},
			target: 'types',
			defaultValue: [],
			cast: (v) => v
		}
	};

	const handleCheckboxChange = (target, value, checked) => {
		setProvisionalFilters((prev) => {
			if (!prev[target]) prev[target] = [];
			if (checked && !prev[target].includes(value)) prev[target] = [...prev[target], value];
			else prev[target] = [...prev[target].filter((t) => t !== value)];
			return { ...prev };
		});
	};

	const submitFilters = (filters = {}) => setFilters(filters);

	const resetFilters = () => {
		setProvisionalFilters({});
		submitFilters({});
	};

	useEffect(() => {
		axios
			.get(API_TYPES_URL, {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			})
			.then((res) => setTypes(res.data));
	}, [API_TYPES_URL, ACCESS_TOKEN]);

	useEffect(() => {
		axios
			.get(API_SERVICES_URL, {
				headers: {
					Authorization: `Bearer ${ACCESS_TOKEN}`
				}
			})
			.then((res) => {
				const categories = res.data;
				const services = [];
				categories?.forEach((c) => c.services.forEach((s) => services.push(s)));
				setServices(services);
			});
	}, [API_SERVICES_URL, ACCESS_TOKEN]);

	return (
		<Box sx={{ boxSizing: 'border-box', padding: '25px', overflow: 'hidden', width }}>
			<Box mb={3} sx={{ textAlign: 'right', cursor: 'pointer', height: '24px' }}>
				<Clear onClick={handleClose} />
			</Box>
			<FormControl
				sx={{ height: 'calc(100% - 24px - 144px)', padding: '0px 5px 0px 5px', overflow: 'auto' }}>
				<Grid container spacing={3}>
					{Object.entries(filterList).map(([k, v]) => {
						const {
							id,
							target,
							defaultValue,
							input: { element },
							labelText,
							cast
						} = v;
						const props = v.input.props || {};
						if (!props.onChange)
							props.onChange = (e) => (provisionalFilters[target] = cast(e.target.value));
						return (
							<InputContainer key={k} item container direction='column' gap={1} xs={12}>
								<BaseLabel htmlFor={`${id}-filter`}>{labelText}</BaseLabel>
								{element({
									name: target,
									defaultValue: cast(
										provisionalFilters[target] || getFilters(target) || defaultValue
									),
									...props
								})}
							</InputContainer>
						);
					})}
				</Grid>
			</FormControl>
			<Grid
				mt={3}
				container
				spacing={2}
				sx={{ textAlign: 'center', color: primaryColor, height: '105px' }}>
				<Grid item container direction='column' gap={1} xs={12}>
					<BaseButton
						variant='contained'
						onClick={() => submitFilters(provisionalFilters)}
						sx={{
							color: primaryTextColor,
							backgroundColor: primaryColor,
							'&:hover, &:focus': { backgroundColor: colorHover }
						}}>
						<Literal lang={lang} text='applyFilters' />
					</BaseButton>
				</Grid>
				<Grid item container direction='column' gap={1} xs={12}>
					<BaseButton onClick={resetFilters} variant='text' color='inherit'>
						<Literal lang={lang} text='reset' />
					</BaseButton>
				</Grid>
			</Grid>
		</Box>
	);
}

export default FilterForm;

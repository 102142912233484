import ibblue from './ibblue.json';
import visitManacor from './visitmanacor.json';
import visitSoller from './visitsoller.json';
import visitCalamillor from './visitcalamillor.json';
import visitaChiclana from './visitachiclana.json';
import fehm from './fehm.json';
import formentera from './formentera.json';

const envConfig = () => {
	const config = {};

	const host = window.location.host;
	const subdomain = host?.split('.')[0];

	let configJson;
	switch (subdomain) {
		case 'visitsoller':
			configJson = visitSoller;
			break;
		case 'visitmanacor':
			configJson = visitManacor;
			break;
		case 'visitcalamillor':
			configJson = visitCalamillor;
			break;
		case 'visitachiclana':
			configJson = visitaChiclana;
			break;
		case 'fehm':
			configJson = fehm;
			break;
		case 'formentera':
			configJson = formentera;
			break;
		default:
			configJson = ibblue;
			break;
	}

	config.HOST = host;
	config.SUBDOMAIN = subdomain;
	config.ACCESS_TOKEN = process.env[`REACT_APP_ACCESS_TOKEN_${configJson.CODE.toUpperCase()}`];
	config.GET_ESTABLISHMENTS_PATH = configJson.GET_ESTABLISHMENTS_PATH;
	config.GET_AVAILABILITY_PATH = configJson.GET_AVAILABILITY_PATH;
	return config;
};

export { envConfig as default };

import { useState, memo, useRef } from 'react';
import { AvailabilityContext } from '../../contexts/AvailabilityContext';
import { UserContext } from '../../contexts/UserContext';
import Literal from './Literal';

import EstablishmentCardGallery from './EstablishmentCardGallery';
import EstablishmentInformation from './EstablishmentInformation';
import EstablishmentPrice from '../sections/EstablishmentPrice';

import { createTheme, ThemeProvider } from '@mui/material';
import {
	styled,
	Grid,
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import axios from 'axios';
import ExternalLinkWarning from './ExternalLinkWarning';
import { useContextSelector } from 'use-context-selector';

const establishmentStars = {
	1: '★',
	2: '★★',
	3: '★★★',
	4: '★★★★',
	5: '★★★★★'
};

function EstablishmentCard({ establishment, dataTheme, lazyLoad }) {
	const borderRadius = dataTheme.borderRadius;
	const borderRadiusLeft = dataTheme.others.borderRadiusLeft;
	const borderRadiusRight = dataTheme.others.borderRadiusRight;
	const activeColor = dataTheme.colors.active.color;
	const deactivatedColor = dataTheme.colors.deactivate.color;
	const ctaDarkColor = dataTheme.cta.dark.color;
	const ctaLightColor = dataTheme.cta.light.color;
	const ctaLightHoverColor = dataTheme.cta.light.hover;
	const ctaTextLightColor = dataTheme.colors.secondary.textColor;
	const colorIcon = dataTheme.iconsInput.color;
	const backgroundIcon = dataTheme.iconsInput.background;
	const lineClamp = dataTheme.others.lineClamp;

	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 700,
				md: 900,
				lg: 1200,
				xl: 1536
			}
		}
	});

	const ButtonCard = styled(Button)(({ theme }) => ({
		width: '100%',
		backgroundColor: `${ctaLightColor}`,
		color: `${ctaTextLightColor}`,
		borderRadius: `${borderRadius}`,
		fontSize: '1rem',
		fontWeight: '400',
		textTransform: 'uppercase',
		lineHeight: 1.5,
		boxShadow: 'none',
		padding: '10px 15px',
		minWidth: '130px',
		whiteSpace: 'nowrap',

		'&:hover, &:focus': {
			backgroundColor: `${ctaLightHoverColor}`,
			fontWeight: '500',
			boxShadow: 'none'
		}
	}));

	const ContentCardEstablishment = styled(CardContent)(({ theme }) => ({
		flexBasis: '60%',
		padding: '30px 20px',

		[theme.breakpoints.down('sm')]: {
			padding: '10px 10px',

			h2: {
				fontSize: '1.5rem'
			}
		}
	}));

	const API_TRACKER_EVENT = `${process.env.REACT_APP_SECURE_TRACK_URL}/url/event`;

	const openInfoBtn = (establishmentId) => {
		if (!!establishmentId)
			axios.get(
				`${API_TRACKER_EVENT}?ibb_establishment=${establishmentId}&ibb_action=info&ibb_origin=${window.location.origin}`
			);
	};

	const { category, name, description, mmc, website, type, location, _id } = establishment;
	const engine = !!establishment.engine && establishment.engine === 'on';

	const isSearching = useContextSelector(AvailabilityContext, (s) => s.isSearching);
	const [isOpened, setOpen] = useState(false);

	const [externalLinkEl, setExternalLinkEl] = useState(null);
	const handleExternalLink = (e) =>
		setExternalLinkEl(
			e.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement
		);

	const lang = useContextSelector(UserContext, (s) => s.lang);

	const ref = useRef();

	return (
		<Grid component='article' style={{ order: '0' }} ref={ref}>
			<Card
				sx={{
					position: 'relative',
					display: 'flex',
					width: '100%',
					borderRadius: `${borderRadius} ${borderRadius} ${isOpened ? 0 : borderRadius} ${
						isOpened ? 0 : borderRadius
					}`,
					transition: '150ms all ease'
				}}>
				<ThemeProvider theme={theme}>
					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 2, sm: 2, md: 3 }}
						sx={{ padding: '0', margin: '0 !important' }}>
						<EstablishmentCardGallery
							gallery={mmc}
							lazy={lazyLoad}
							defaultAlt={name[lang]}
							theme={dataTheme}
						/>
						<Grid item md={8} sm={12} xs={12} sx={{ padding: '0 !important' }}>
							<FiberManualRecordIcon
								fontSize='small'
								sx={{
									position: 'absolute',
									top: 10,
									right: 10,
									color: engine ? activeColor : deactivatedColor,
									opacity: 0.2
								}}
							/>
							<ContentCardEstablishment>
								<Grid container sx={{ gap: '10px' }}>
									<Typography
										gutterBottom
										component='p'
										sx={{
											textTransform: 'uppercase',
											fontWeight: '500',
											marginTop: '0',
											marginBottom: '0',
											color: `${ctaDarkColor}`
										}}>
										{!!location?.address?.locality ? location.address.locality : ''}
									</Typography>
									<Typography
										gutterBottom
										component='p'
										sx={{
											textTransform: 'uppercase',
											fontWeight: '500',
											marginTop: '0',
											marginBottom: '0',
											color: `${ctaDarkColor}`
										}}>
										(<Literal lang={lang} text={type.toLowerCase()} />)
									</Typography>
								</Grid>

								<Typography
									gutterBottom
									variant='h3'
									component='h2'
									sx={{
										textTransform: 'uppercase',
										fontSize: '2.25rem',
										fontWeight: '700',
										lineHeight: '50px',
										marginTop: '0',
										marginBottom: '0'
									}}>
									{name.es}
								</Typography>

								<Typography
									variant='span'
									sx={{ fontSize: '1.5rem', display: 'block', marginBottom: '15px' }}>
									{establishmentStars[category]}
								</Typography>

								<Typography
									component='p'
									className='description'
									sx={{
										fontSize: '0.97rem',
										display: '-webkit-box',
										overflow: 'hidden',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: `${lineClamp}`
									}}>
									{description ? description[lang] || '' : ''}
								</Typography>

								<CardActions sx={{ marginTop: '30px', padding: '0' }}>
									<Grid
										container
										rowSpacing={2}
										columnSpacing={2}
										sx={{ gap: '10px', padding: '0', margin: '0 !important' }}>
										<Grid
											item
											md={isSearching && establishment.engine === 'on' ? 5 : !!website ? 6.9 : 12}
											sm={12}
											xs={12}
											sx={{
												display: 'flex',
												padding: '0 !important',
												maxWidth: '100% !important'
											}}
											onClick={() => setOpen(!isOpened)}>
											<KeyboardArrowDownIcon
												sx={{
													transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
													padding: '10px',
													fontSize: '1.5rem',
													borderRadius: isOpened ? borderRadiusRight : borderRadiusLeft,
													color: `${colorIcon}`,
													backgroundColor: `${backgroundIcon}`,
													cursor: 'pointer'
												}}
											/>
											<ButtonCard
												sx={{ width: '100%', borderRadius: `${borderRadiusRight}` }}
												onClick={() => (!isOpened ? openInfoBtn(_id) : null)}>
												<Literal lang={lang} text={isOpened ? 'hideInfo' : 'moreInfo'} />
											</ButtonCard>
										</Grid>
										{website ? (
											<Grid
												item
												md={isSearching && establishment.engine === 'on' ? 3 : 4.8}
												sm={12}
												xs={12}
												sx={{ textAlign: 'center', padding: '0 !important' }}>
												<ButtonCard
													size='medium'
													sx={{ width: '100%' }}
													onClick={handleExternalLink}>
													<Literal lang={lang} text='website' />
												</ButtonCard>

												{Boolean(externalLinkEl) && (
													<ExternalLinkWarning
														theme={dataTheme}
														resolve={() =>
															window.open(
																`${process.env.REACT_APP_SECURE_TRACK_URL}/url/website/${website.hash}?ibb_establishment=${_id}&ibb_origin=${window.location.origin}`,
																'_blank'
															)
														}
														reject={() => setExternalLinkEl(null)}
														onClickAway={() => setExternalLinkEl(null)}
														anchorEl={externalLinkEl}
														opened={Boolean(externalLinkEl)}
														popperProps={{ placement: isSearching ? 'bottom' : 'bottom-end' }}
													/>
												)}
											</Grid>
										) : (
											<></>
										)}
										{establishment.engine === 'on' ? (
											<Grid
												item
												md={3.6}
												sm={12}
												xs={12}
												sx={{
													padding: '0 !important',
													display: `${isSearching ? 'block' : 'none'}`
												}}>
												<EstablishmentPrice
													id={establishment._id}
													dataTheme={dataTheme}
													parentRef={ref}
												/>
											</Grid>
										) : (
											<></>
										)}
									</Grid>
								</CardActions>
							</ContentCardEstablishment>
						</Grid>
					</Grid>
				</ThemeProvider>
			</Card>
			<Collapse in={isOpened}>
				<EstablishmentInformation
					establishment={establishment}
					theme={dataTheme}
					loadMap={isOpened}
				/>
			</Collapse>
		</Grid>
	);
}

function areEqual(prev, next) {
	return JSON.stringify(prev) === JSON.stringify(next);
}

export default memo(EstablishmentCard, areEqual);

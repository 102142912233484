import ca from './ca.json';
import es from './es.json';
import en from './en.json';
import fr from './fr.json';
import de from './de.json';
import it from './it.json';
import nl from './nl.json';

const defaultLang = 'en';
const langFiles = { ca, es, en, fr, de, it, nl };

const availableLanguages = {};
Object.keys(langFiles).forEach((lang) => {
	availableLanguages[lang] = langFiles[lang].language;
});

const isValidLanguage = (lang) => Object.keys(langFiles).some((k) => k === lang);

const getAvailableLanguages = () => availableLanguages;

const translation = (lang, target) => {
	const file = isValidLanguage(lang) ? langFiles[lang] : langFiles[defaultLang];
	return file[target];
};

const getCookie = (cname) => {
	let cookie = {};
	document.cookie.split(';').forEach(function (el) {
		let [key, value] = el.split('=');
		cookie[key.trim()] = value;
	});
	return cookie[cname];
};

const getDefaultLanguage = () => {
	const cookieLang = getCookie('lang');
	if (!!cookieLang && isValidLanguage(cookieLang)) return cookieLang;

	const browserLang = window.navigator.language.split('-')[0];
	return isValidLanguage(browserLang) ? browserLang : defaultLang;
};

export { translation, isValidLanguage, getAvailableLanguages, getDefaultLanguage, defaultLang };

import { styled, Link } from '@mui/material';

function Logo({ logo, width, height, title, web }) {
	const StyledLogo = styled('img')(({ theme }) => ({
		display: 'block',
		height: '60px',
		width: 'auto',
		maxWidth: '260px',
		filter: 'brightness(0) invert(1)',
		objectFit: 'contain'
	}));

	return (
		<Link href={web} className='logo text-uppercase' target='_blank' rel='noreferrer'>
			<StyledLogo src={logo} alt={title} width={width} height={height} />
		</Link>
	);
}

export default Logo;

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

function EstablishmentSkeleton({ dataTheme }) {
	const ContentCardEstablishment = styled(CardContent)(({ theme }) => ({
		flexBasis: '60%',
		padding: '30px 20px',

		[theme.breakpoints.down('sm')]: {
			h2: {
				fontSize: '1.5rem'
			}
		}
	}));

	return (
		<Card sx={{ display: 'flex', width: '100%' }}>
			<Grid
				container
				rowSpacing={1}
				columnSpacing={{ xs: 2, sm: 2, md: 3 }}
				sx={{ alignItems: 'center', padding: '0', margin: '0 !important' }}
			>
				<Grid item md={4} sx={{ padding: '0 !important', height: '100%' }}>
					<Skeleton variant='rectangular' width='100%' height='100%' sx={{ display: 'block' }} />
				</Grid>
				<Grid item md={8} sm={12} xs={12} sx={{ padding: '0 !important' }}>
					<ContentCardEstablishment>
						<Stack spacing={1}>
							<Typography variant='span' sx={{ fontSize: '1.5rem', display: 'block' }}>
								{<Skeleton variant='rectangular' width='30%' />}
							</Typography>

							<Typography
								gutterBottom
								variant='h3'
								component='h2'
								sx={{
									textTransform: 'uppercase',
									fontSize: '2.25rem',
									fontWeight: '700',
									lineHeight: '50px',
									marginBottom: '0'
								}}
							>
								{<Skeleton variant='rectangular' width='70%' />}
							</Typography>

							<Typography
								variant='span'
								sx={{ fontSize: '1.5rem', display: 'block', marginBottom: '30px' }}
							>
								{<Skeleton variant='rectangular' width='100px' />}
							</Typography>

							<Typography variant='p' id='description' sx={{ fontSize: '0.97rem', lineClamp: '3' }}>
								<Skeleton variant='rectangular' width='93%' height='120px' />
							</Typography>

							<CardActions sx={{ marginTop: '50px', padding: '0' }}>
								<Grid
									container
									rowSpacing={2}
									columnSpacing={{ xs: 2, sm: 2, md: 2 }}
									sx={{ alignItems: 'center', gap: '10px', padding: '0', margin: '0 !important' }}
								>
									<Grid item md={5.5} xs={11.2} sx={{ padding: '0 !important', height: '100%' }}>
										<Skeleton variant='rectangular' width='100%' height='50px' />
									</Grid>

									<Grid item md={5.5} xs={11.2} sx={{ padding: '0 !important' }}>
										<Skeleton variant='rectangular' width='100%' height='50px' />
									</Grid>
								</Grid>
							</CardActions>
						</Stack>
					</ContentCardEstablishment>
				</Grid>
			</Grid>
		</Card>
	);
}

export default EstablishmentSkeleton;

import { memo, useState, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import servicesList from './services/servicesList';
import Literal from './Literal';

import { createTheme, ThemeProvider } from '@mui/material';
import { Box, styled, Card, CardMedia, Grid, Typography, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useContextSelector } from 'use-context-selector';

function EstablishmentInformation({ establishment, theme, loadMap }) {
	const breakpoints = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 700,
				md: 900,
				lg: 1200,
				xl: 1536
			}
		}
	});
	const borderRadius = theme.borderRadius;
	const colorTitleShowMore = theme.others.colorTitleShowMore;
	const colorArrowToolTip = theme.others.colorArrowTooltip;
	const colorTooltip = theme.others.colorTooltip;
	const Title = styled(Typography)(({ theme }) => ({
		color: colorTitleShowMore,
		fontSize: '1.3rem',
		fontWeight: '500',
		textTransform: 'uppercase'
	}));

	const ListServices = styled(Box)(({ theme }) => ({
		display: 'flex',
		gap: '10px',
		margin: '5px 0 15px 0'
	}));
	const ServiceTooltip = styled(Tooltip)(({ theme }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: colorArrowToolTip
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: colorTooltip,
			fontSize: '0.97rem',
			textAlign: 'center',
			textTransform: 'uppercase',
			margin: '0 auto',
			padding: '10px 15px',
			borderRadius: `${borderRadius}`
		}
	}));
	const GridContainer = styled(Grid)(({ theme }) => ({
		alignItems: 'center',
		gap: '20px',
		margin: '0 !important',
		padding: '20px 55px',
		width: '100%!important',
		[theme.breakpoints.down('md')]: {
			padding: '24px'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px'
		}
	}));

	const MapGrid = styled(Grid)(({ theme }) => ({
		padding: '0 !important',
		height: '300px',

		[theme.breakpoints.down('md')]: {
			height: '190px',
			width: '100%'
		}
	}));
	const MapCard = styled(CardMedia)(({ theme }) => ({
		height: '100%',
		width: '100%',

		[theme.breakpoints.down('md')]: {
			height: '190px'
		}
	}));

	const { name, description, location, services, contact } = establishment;
	const API_URL = `
		https://www.google.com/maps/embed/v1/place
		?key=${process.env.REACT_APP_ACCESS_TOKEN_MAP}
		&zoom=12
		&center=${location?.longitude}, ${location?.latitude}
		&q=${encodeURIComponent(name.es)}, ${encodeURIComponent(location?.address?.street)}
	`;

	const lang = useContextSelector(UserContext, (s) => s.lang);

	const [isMapLoaded, setMapLoaded] = useState(false);

	useEffect(() => {
		if (isMapLoaded || !loadMap) return;
		setMapLoaded(true);
		return () => {};
	}, [isMapLoaded, loadMap]);

	return (
		<Card sx={{ borderRadius: `0 0 ${borderRadius} ${borderRadius}` }}>
			<ThemeProvider theme={breakpoints}>
				<GridContainer container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
					{!!description && !!description[lang] && description[lang] !== '' ? (
						<Grid item md={12} sm={12} xs={12} sx={{ padding: '0 !important' }}>
							<Title component='h3' className='title'>
								<Literal lang={lang} text='description' />
							</Title>
							<Typography
								component='p'
								className='description'
								sx={{
									fontSize: '0.97rem',
									margin: '15px 0 15px 0'
								}}
								dangerouslySetInnerHTML={{
									__html: description[lang]?.replace(/(\n)/gm, '<br />')
								}}></Typography>
						</Grid>
					) : (
						<></>
					)}

					{services.length > 0 ? (
						<Grid item md={12} sm={12} xs={12} sx={{ padding: '0 !important' }}>
							<Title mb={2} component='h3' className='title'>
								<Literal lang={lang} text='services' />
							</Title>
							<ListServices>
								{(services || [])
									.filter((s) => !!servicesList[`s_${s._id}`]?.icon)
									.map((s) => (
										<ServiceTooltip key={s._id} title={s.name[lang]} arrow>
											{servicesList[`s_${s._id}`].icon}
										</ServiceTooltip>
									))}
							</ListServices>
						</Grid>
					) : (
						<></>
					)}

					{contact ? (
						<Grid item md={12} sm={12} xs={12} sx={{ padding: '0 !important' }}>
							<Title component='h3' className='title'>
								<Literal lang={lang} text='contact' />
							</Title>
							<Box sx={{ display: 'flex', gap: '70px' }}>
								<Typography
									component='a'
									className='email'
									color='inherit'
									href={`mailto:${contact.email}`}
									sx={{ fontSize: '0.97rem', margin: '15px 0 15px 0' }}>
									{contact.email}
								</Typography>
								<Typography
									component='a'
									className='phone'
									color='inherit'
									href={`tel:+${contact.phonePrefix} ${contact.phone}`}
									sx={{ fontSize: '0.97rem', margin: '15px 0 15px 0' }}>
									+{contact.phonePrefix} {contact.phone}
								</Typography>
							</Box>
						</Grid>
					) : (
						<></>
					)}
				</GridContainer>
				<MapGrid item md={12} sm={12} xs={12}>
					{isMapLoaded ? (
						<MapCard loading='lazy' component='iframe' alt={name.es} src={API_URL} />
					) : (
						<></>
					)}
				</MapGrid>
			</ThemeProvider>
		</Card>
	);
}

function areEqual(prev, next) {
	return JSON.stringify(prev) === JSON.stringify(next);
}

export default memo(EstablishmentInformation, areEqual);

import { useContext, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { CookiesContext } from '../../contexts/CookiesContext';
import Literal from '../utilities/Literal';

import {
	Box,
	Button,
	Card,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grow,
	styled
} from '@mui/material';
import { useContextSelector } from 'use-context-selector';

function Cookies({ theme, user }) {
	const ctaColor = theme.cta.primary.color;
	const ctaTextColor = theme.cta.primary.textColor;
	const ctaHoverColor = theme.others.ctaHoverColor;
	const ctaLightColor = theme.cta.light.color;
	const ctaDarkColor = theme.cta.dark.color;
	const borderRadius = theme.borderRadius;

	const lang = useContextSelector(UserContext, (s) => s.lang);
	const { saveCookie, checkCookie } = useContext(CookiesContext);

	const Cookies = styled(Card)(() => ({
		position: 'fixed',
		bottom: '10px',
		padding: '35px !important',
		borderRadius: `${borderRadius}`,
		boxSizing: 'border-box',
		zIndex: 10
	}));

	const ThemeButton = styled(Button)(({ theme }) => ({
		position: 'relative',
		color: `${ctaTextColor}`,
		backgroundColor: `${ctaColor}`,
		border: `1px solid ${ctaTextColor}`,
		borderRadius: `${borderRadius}`,
		boxShadow: 'none',
		'&:hover': {
			color: `${ctaLightColor}`,
			backgroundColor: `${ctaHoverColor}`,
			border: '1px solid transparent'
		}
	}));

	const [isSaved, setSaved] = useState(checkCookie('cookiesChecked'));
	const [analyticCookies, setAnalyticCookies] = useState(true);
	const [commercialCookies, setCommercialCookies] = useState(true);

	const checkCookies = (reject = false) => {
		const expirationDays = reject || !analyticCookies ? 0 : 30;
		saveCookie('cookiesChecked', true, expirationDays);
		saveCookie('functionalCookies', true, expirationDays);
		saveCookie('analyticCookies', !reject && analyticCookies, expirationDays);
		saveCookie('commercialCookies', !reject && commercialCookies, expirationDays);
		setSaved(true);
	};

	return (
		<Grow in={!isSaved}>
			<Cookies className='container'>
				<Box component={'p'}>
					<Literal lang={lang} text={'cookiesMsg'} />
				</Box>
				<FormGroup style={{ flexDirection: 'row', marginTop: 15 }}>
					<FormControlLabel
						control={
							<Checkbox
								style={{ color: ctaColor }}
								checked={analyticCookies}
								onChange={({ target }) => setAnalyticCookies(target.checked)}
							/>
						}
						label={<Literal lang={lang} text='analyticCookies' />}
					/>
					<FormControlLabel
						control={
							<Checkbox
								style={{ color: ctaColor }}
								checked={commercialCookies}
								onChange={({ target }) => setCommercialCookies(target.checked)}
							/>
						}
						label={<Literal lang={lang} text='commercialCookies' />}
					/>
					<FormControlLabel
						disabled
						control={<Checkbox style={{ color: ctaColor }} defaultChecked />}
						label={<Literal lang={lang} text='functionalCookies' />}
					/>
				</FormGroup>
				<Box style={{ display: 'flex', justifyContent: 'end', gap: 15 }}>
					<Button variant='text' style={{ color: ctaDarkColor }} onClick={() => checkCookies(true)}>
						<Literal lang={lang} text='rejectCookies' />
					</Button>
					<ThemeButton variant='contained' onClick={() => checkCookies()}>
						<Literal lang={lang} text='acceptCookies' />
					</ThemeButton>
				</Box>
			</Cookies>
		</Grow>
	);
}

export default Cookies;

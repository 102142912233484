import WifiIcon from '@mui/icons-material/Wifi';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded';
import PoolRoundedIcon from '@mui/icons-material/PoolRounded';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import SpaRoundedIcon from '@mui/icons-material/SpaRounded';
import FamilyRestroomRoundedIcon from '@mui/icons-material/FamilyRestroomRounded';
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import LocalFloristRoundedIcon from '@mui/icons-material/LocalFloristRounded';
import WaterRoundedIcon from '@mui/icons-material/WaterRounded';
import SystemSecurityUpdateGoodRoundedIcon from '@mui/icons-material/SystemSecurityUpdateGoodRounded';
import TaxiAlertRoundedIcon from '@mui/icons-material/TaxiAlertRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

const services = {
	s_61d41fc73b5fb1ebe3a1a884: {
		ref: 'Wifi',
		icon: <WifiIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41f263b5fb1ebe3a1a874: {
		ref: 'Bar',
		icon: <LocalBarIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41da43b5fb1ebe3a1a854: {
		ref: 'Parking',
		icon: <LocalParkingRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41e4d3b5fb1ebe3a1a85c: {
		ref: 'Parking Free',
		icon: <LocalParkingRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41eaa3b5fb1ebe3a1a864: {
		ref: 'Pool',
		icon: <PoolRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41f0d3b5fb1ebe3a1a86c: {
		ref: 'Restaurant',
		icon: <RestaurantRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d41f9f3b5fb1ebe3a1a87c: {
		ref: 'Spa & WellnessCenter',
		icon: <SpaRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d420923b5fb1ebe3a1a88c: {
		ref: 'Family Rooms',
		icon: <FamilyRestroomRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d420f53b5fb1ebe3a1a8ae: {
		ref: 'Gym',
		icon: <FitnessCenterRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d4228f3b5fb1ebe3a1a8cd: {
		ref: 'Meeting/Banquet facilities',
		icon: <MeetingRoomRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d423443b5fb1ebe3a1a8d5: {
		ref: 'ECO Sustainable initiatives',
		icon: <LocalFloristRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d424133b5fb1ebe3a1a8dd: {
		ref: 'Adults only',
		icon: <LocalParkingRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d31a9a3b5fb1ebe3a1a587: {
		ref: 'Sea View',
		icon: <WaterRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d424693b5fb1ebe3a1a8e8: {
		ref: 'CheckIn online',
		icon: <SystemSecurityUpdateGoodRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d424f33b5fb1ebe3a1a8f0: {
		ref: 'Airport pick up',
		icon: <TaxiAlertRoundedIcon sx={{ fontSize: '2rem' }} />
	},
	s_61d425c83b5fb1ebe3a1a8fb: {
		ref: 'All Included',
		icon: <TaskAltRoundedIcon sx={{ fontSize: '2rem' }} />
	}
};

export { services as default };

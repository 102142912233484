import React from 'react';
import { UserContext } from '../../contexts/UserContext';

import Literal from './Literal';
import { Box, Typography } from '@mui/material';
import { useContextSelector } from 'use-context-selector';

function NoEstablishmentsFound({ theme }) {
	const deactivateColor = theme.colors.deactivate.color;

	const lang = useContextSelector(UserContext, (s) => s.lang);

	return (
		<Box sx={{ padding: '200px 0', textAlign: 'center', opacity: 0.7 }}>
			<Typography
				component={'h3'}
				variant={'h2'}
				sx={{
					position: 'relative',
					fontWeight: 500,
					textTransform: 'uppercase',
					color: deactivateColor
				}}>
				<Typography
					component={'span'}
					variant={'h1'}
					sx={{
						position: 'absolute',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						fontSize: '15rem',
						fontWeight: 600,
						opacity: 0.2,
						color: deactivateColor
					}}>
					404
				</Typography>
				<Literal lang={lang} text='noEstablishmentsFound' />
			</Typography>
			<Typography
				component={'p'}
				variant={'body1'}
				sx={{ textTransform: 'uppercase', color: deactivateColor }}>
				<Literal lang={lang} text='doOtherSearch' />
			</Typography>
		</Box>
	);
}

export default NoEstablishmentsFound;

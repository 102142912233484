import { createContext } from 'react';

window.dataLayer = window.dataLayer || [];
function gtag() {
	window.dataLayer.push(arguments);
}

const getCookies = () => {
	const cookies = document.cookie.split(';');
	const cookiesArray = [];
	for (let i = 1; i <= cookies.length; i++) {
		const cookieSplit = cookies[i - 1].split('=');
		cookiesArray.push([cookieSplit[0].trim(), !!cookieSplit[1] ? cookieSplit[1].trim() : 'false']);
	}
	return cookiesArray;
};

const getCookie = (name) => getCookies().find((c) => c[0] === name);
const checkCookie = (name) => !!getCookies().find((c) => c[0] === name);

const executeOnSave = () => {
	const analytics = checkCookie('analyticCookies') && getCookie('analyticCookies')[1] === 'true';
	const ad = checkCookie('commercialCookies') && getCookie('commercialCookies')[1] === 'true';
	gtag('consent', 'update', {
		ad_storage: ad ? 'granted' : 'denied',
		analytics_storage: analytics ? 'granted' : 'denied'
	});
};

const saveCookie = (name, value, days) => {
	const d = new Date();
	d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
	let expires = 'expires=' + d.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
	executeOnSave();
};

export const CookiesContext = createContext({ saveCookie, getCookies, getCookie, checkCookie });

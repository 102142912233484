import React, { useEffect, useState } from 'react';
import CalendarReservation from './CalendarReservation';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, styled } from '@mui/material';
import dayjs from 'dayjs';
import { AvailabilityContext } from '../../../contexts/AvailabilityContext';
import { useContextSelector } from 'use-context-selector';

const dateFormat = 'DD-MM-YYYY';
const dateSearchFormat = 'YYYY-MM-DD';
const height = 400;

const DateInput = ({ date, inputRef, InputCalendar }) => {
	return (
		<InputCalendar className='text-uppercase' ref={inputRef} data-dateformat={dateFormat}>
			{date?.format(dateFormat) || dateFormat}
		</InputCalendar>
	);
};

const DateInputBox = ({ checkin, checkout, inputsRef, theme }) => {
	const InputCalendar = styled('span')(() => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: `${theme.ctaDarkColor}`,
		border: 'none',
		borderRadius: `${theme.borderRadiusRight}`,
		fontSize: '0.9rem',
		fontWeight: '500',
		textTransform: 'uppercase',
		textAlign: 'center',
		height: '100%',
		appearance: 'none'
	}));

	return (
		<>
			<DateInput
				date={checkin}
				inputRef={inputsRef?.checkin}
				theme={theme}
				InputCalendar={InputCalendar}
			/>
			<InputCalendar sx={{ mx: 0.5 }}>{'-'}</InputCalendar>
			<DateInput
				date={checkout}
				inputRef={inputsRef?.checkout}
				theme={theme}
				InputCalendar={InputCalendar}
			/>
		</>
	);
};

function Calendar({ dataTheme, lang, error, setHeight }) {
	const errorColor = dataTheme.colors.error.color;
	const ctaLightColor = dataTheme.cta.light.color;
	const ctaDarkColor = dataTheme.cta.dark.color;
	const borderRadiusRight = dataTheme.others.borderRadiusRight;
	const borderRadiusLeft = dataTheme.others.borderRadiusLeft;
	const colorIcon = dataTheme.iconsInput.color;
	const backgroundIcon = dataTheme.iconsInput.background;

	const BoxCalendar = styled(Box)(() => ({
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: `${error ? errorColor : ctaLightColor}`,
		borderRadius: `${borderRadiusRight}`,
		padding: '0 !important',
		width: '100%',
		cursor: 'pointer'
	}));

	// Availability Context
	const getSearch = useContextSelector(AvailabilityContext, (s) => s.getSearch);
	const search = getSearch();

	const [isOpen, setOpen] = useState(false);
	const [from, setFrom] = useState(search.from ? dayjs(search.from, dateSearchFormat) : undefined);
	const [to, setTo] = useState(search.to ? dayjs(search.to, dateSearchFormat) : undefined);

	const [firstLoad, setFirstLoad] = useState(true);
	useEffect(() => {
		if (firstLoad) return;
		if (!isOpen) setHeight(0);
		else setHeight(height);
		setFirstLoad(false);
	}, [isOpen, firstLoad, setHeight]);

	return (
		<>
			<input type='hidden' name='from' value={from?.format(dateFormat) || ''} />
			<input type='hidden' name='to' value={to?.format(dateFormat) || ''} />

			<DateRangeIcon
				sx={{
					backgroundColor: `${backgroundIcon}`,
					color: `${colorIcon}`,
					borderRadius: `${borderRadiusLeft}`,
					fontSize: '1.8rem',
					padding: '10px',
					height: '1em'
				}}
			/>

			<BoxCalendar onClick={() => setOpen(true)}>
				{isOpen ? (
					<CalendarReservation
						checkinCheckout={[from, to]}
						setCheckinCheckout={([from, to]) => {
							setFrom(from);
							setTo(to);
						}}
						setCalendarVisible={setOpen}
						DateInputBox={DateInputBox}
						lang={lang}
						theme={dataTheme}
					/>
				) : (
					<DateInputBox checkin={from} checkout={to} theme={{ ctaDarkColor, borderRadiusRight }} />
				)}
			</BoxCalendar>
		</>
	);
}

export default Calendar;

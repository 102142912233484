import { useState, useEffect, useCallback, useRef } from 'react';

import { AvailabilityContext } from '../../../contexts/AvailabilityContext';
import { UserContext } from '../../../contexts/UserContext';
import Literal from '../../utilities/Literal';

import PoweredBy from '../../widgets/PoweredBy';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Button, FormControl, Card, Grid } from '@mui/material';
import { HotelRounded } from '@material-ui/icons';
import Paxes from './Paxes';
import Calendar from './Calendar';
import Search from './Search';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Base64 from '../../../utils/Base64';
import dayjs from 'dayjs';
import { useContextSelector } from 'use-context-selector';

const dateFormat = 'DD-MM-YYYY';
const dateSearchFormat = 'YYYY-MM-DD';
function parseIfJSON(str) {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
}

function BookingSearch({ dataTheme, widget, user }) {
	const secondaryColor = dataTheme.colors.secondary.color;
	const ctaColor = dataTheme.cta.primary.color;
	const ctaTextColor = dataTheme.cta.primary.textColor;
	const ctaLightColor = dataTheme.cta.light.color;
	const ctaDarkColor = dataTheme.cta.dark.color;
	const ctaBorderRadius = dataTheme.cta.borderRadius;
	const ctaHoverPaxes = dataTheme.others.colorHover;
	const ctaHoverSearch = dataTheme.others.ctaHoverColor;
	const borderRadius = dataTheme.borderRadius;
	const borderRadiusRight = dataTheme.others.borderRadiusRight;
	const borderRadiusLeft = dataTheme.others.borderRadiusLeft;
	const colorIcon = dataTheme.iconsInput.color;
	const backgroundIcon = dataTheme.iconsInput.background;
	const showPoweredBy = !!dataTheme.showPoweredBy && dataTheme.showPoweredBy;

	// Breakpoints
	const breakpoints = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 480,
				md: 990
			}
		}
	});
	//
	// New components
	const SectionBooking = styled('section')(({ theme }) => ({
		position: widget ? 'absolute' : 'relative',
		width: widget ? '100%' : 'auto',
		display: 'flex',
		justifyContent: 'center',
		marginTop: widget ? '0' : '-70px',
		zIndex: widget ? '0' : 10
	}));

	const ThemeCard = styled(Card)(({ theme }) => ({
		position: 'relative',
		backgroundColor: `${secondaryColor}`,
		borderRadius: `${borderRadius}`,
		padding: widget ? '25px 30px' : '40px 50px',
		overflow: 'visible',

		[theme.breakpoints.down('sm')]: {
			padding: showPoweredBy ? '20px 20px 25px' : '20px'
		}
	}));

	const ThemeButton = styled(Button)(({ theme }) => ({
		position: 'relative',
		color: `${ctaTextColor}`,
		backgroundColor: `${ctaColor}`,
		border: `1px solid ${ctaTextColor}`,
		borderRadius: `${ctaBorderRadius}`,
		boxShadow: 'none',
		'&:hover': {
			color: `${ctaLightColor}`,
			backgroundColor: `${ctaHoverSearch}`,
			border: 'none'
		}
	}));

	// Availability Context
	const setSearch = useContextSelector(AvailabilityContext, (s) => s.setSearch);
	const setSearching = useContextSelector(AvailabilityContext, (s) => s.setSearching);
	const getFilters = useContextSelector(AvailabilityContext, (s) => s.getFilters);
	const setFilters = useContextSelector(AvailabilityContext, (s) => s.setFilters);
	const getSorting = useContextSelector(AvailabilityContext, (s) => s.getSorting);
	const lang = useContextSelector(UserContext, (s) => s.lang);

	const navigate = useNavigate();

	const bookingSearchRef = useRef();

	const [externalSource, setExternalSource] = useState();
	const [height, setHeight] = useState();
	useEffect(() => {
		if (!widget && !user) return;
		if (!bookingSearchRef?.current) return;

		const domObserver = (e) => {
			if (!externalSource) return;
			const height = document.body.querySelector('#booking_search').scrollHeight;
			const search = document.body.querySelector('.MuiAutocomplete-popper')?.offsetHeight || 0;
			const calendar = document.body.querySelector('.search_calendar')?.offsetHeight || 0;
			externalSource.postMessage(height + search + calendar, '*');
		};

		const getExternalSource = (event) => {
			const height = document.body.querySelector('#booking_search').scrollHeight;
			if (event.data === 'getHeight') event.source.postMessage(height, '*');
			setExternalSource(event.source);
		};

		if (!externalSource) window.addEventListener('message', getExternalSource, true);
		document.body.addEventListener('DOMSubtreeModified', domObserver, true);

		return () => {
			document.body.removeEventListener('DOMSubtreeModified', domObserver, true);
			window.removeEventListener('message', getExternalSource, true);
		};
	}, [height, bookingSearchRef, externalSource, user, widget]);

	const handleSearchAction = useCallback(
		(e) => {
			e.preventDefault();

			setSearching(false);
			const data = {};
			const formData = new FormData(e.target);
			formData.forEach((value, key) => (data[key] = parseIfJSON(value)));

			const sort = getSorting();
			const filter = { ...getFilters() };
			filter.search = '';
			filter.destinations = '';
			filter[`name.${lang}`] = '';
			if (data.search && data.searchType === 'mixed') filter.search = data.search;
			else if (data.searchType === 'destination') filter.destinations = data.search;
			else if (data.searchType === 'establishment') filter[`name.${lang}`] = data.search;
			if (data.from) data.from = dayjs(data.from, dateFormat).format(dateSearchFormat);
			if (data.to) data.to = dayjs(data.to, dateFormat).format(dateSearchFormat);
			setFilters(filter);

			const encrypted = Base64.encode(JSON.stringify({ search: data, filter, sort }));
			if (!data.from || !data.to) {
				if (widget) return window.open(`/?s=${encrypted}`, '_blank');
				return;
			}

			const API_TRACKER_EVENT = `${process.env.REACT_APP_SECURE_TRACK_URL}/url/event`;
			const referer =
				window.location !== window.parent.location ? document.referrer : document.location.href;
			if (!!referer && !referer.includes('localhost'))
				axios.get(
					`${API_TRACKER_EVENT}?ibb_origin=${
						new URL(referer).host
					}&ibb_action=widgetSearch&ibb_search=${encodeURI(JSON.stringify(data))}`
				);

			if (widget) return window.open(`/?s=${encrypted}&search=true`, '_blank');

			navigate(`/?s=${encrypted}`, { replace: true });
			setSearch(data);
			setSearching(true);
		},
		[setSearch, setSearching, getFilters, setFilters, getSorting, navigate, widget, lang]
	);

	return (
		<ThemeProvider theme={breakpoints}>
			<SectionBooking id='booking_search' ref={bookingSearchRef}>
				<FormControl
					component={'form'}
					className={widget ? '' : 'container'}
					fullWidth
					onSubmit={handleSearchAction}>
					<ThemeCard className='card'>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 2, sm: 2, md: 2 }}
							sx={{ alignItems: 'center' }}>
							<Grid item md={3} sm={12} xs={12} className='btn-information'>
								<Search
									key={'search'}
									lang={lang}
									dataTheme={dataTheme}
									setHeight={(h) => setHeight((p) => ({ ...p, search: h }))}
								/>
							</Grid>

							<Grid item md={4} sm={12} xs={12} className='btn-information'>
								<Calendar
									key={'calendar'}
									dataTheme={dataTheme}
									lang={lang}
									setHeight={(h) => setHeight((p) => ({ ...p, calendar: h }))}
								/>
							</Grid>

							<Grid item md={3} sm={12} xs={12} className='btn-information'>
								<Paxes
									key={'paxes'}
									lang={lang}
									theme={{
										ctaHoverPaxes,
										ctaColor,
										ctaLightColor,
										ctaDarkColor,
										borderRadiusRight,
										borderRadiusLeft,
										borderRadius,
										backgroundIcon,
										colorIcon
									}}
									setHeight={(h) => setHeight((p) => ({ ...p, paxes: h }))}
								/>
							</Grid>

							<Grid item md={2} sm={12} xs={12} sx={{ textAlign: 'center' }}>
								<ThemeButton
									type='submit'
									variant='contained'
									color='primary'
									sx={{ minHeight: '48.8px', height: '100%', width: '100%' }}
									endIcon={
										widget ? <HotelRounded fontSize='large' sx={{ marginBottom: '2px' }} /> : false
									}>
									<Literal lang={lang} text='search' />
								</ThemeButton>
							</Grid>
						</Grid>
						{showPoweredBy ? (
							<PoweredBy
								style={{
									position: 'absolute',
									right: '20px',
									bottom: '10px',
									transform: 'translateY(30%)'
								}}
							/>
						) : (
							<></>
						)}
					</ThemeCard>
				</FormControl>
			</SectionBooking>
		</ThemeProvider>
	);
}

export default BookingSearch;
